export function registerKeyEventHandler(callback) {
  return () => {
    console.log("Register key event");
    document.addEventListener('keyup', (event) => {
      callback(event.key)();
    }, false);
  };
};

var _addNameCB = null;
export function registerAddNameHandler(callback) {
  return () => {
    _addNameCB = callback;
  }
};
window.addName = function(name) {
  _addNameCB && _addNameCB(name)();
}

var _removeNameCB = null;
export function registerRemoveNameHandler(callback) {
  return () => {
    _removeNameCB = callback;
  }
};
window.removeName = function(name) {
  _removeNameCB && _removeNameCB(name)();
}

var _getNamesCB = null;
export function registerGetNamesHandler(callback) {
  return () => {
    _getNamesCB = callback;
  }
};
window.getNames = function() {
  return _getNamesCB && _getNamesCB();
}

var _getOrderCB = null;
export function registerGetOrderHandler(callback) {
  return () => {
    _getOrderCB = callback;
  }
};
window.getOrder = function() {
  return _getOrderCB && _getOrderCB();
}

var _getWorldCB = null;
export function registerGetWorldHandler(callback) {
  return () => {
    _getWorldCB = callback;
  }
};
window.getWorld = function() {
  return _getWorldCB && _getWorldCB();
}