// Generated by purs version 0.15.10
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Graphics_Matrix from "../Graphics.Matrix/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Record from "../Record/index.js";
var merge = /* #__PURE__ */ Record.merge()();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var matrixAttributePointer = function (ctx) {
    return function (attr) {
        return function (offset) {
            return function (stride) {
                var common = {
                    size: 4,
                    normalized: false,
                    stride: stride
                };
                return function __do() {
                    Graphics_WebGL.vertexAttributePointer(ctx)(attr)(merge(common)({
                        offset: offset
                    }))();
                    Graphics_WebGL.vertexAttributeDivisor(ctx)(attr)(1)();
                    Graphics_WebGL.vertexAttributePointer(ctx)(Graphics_WebGL.webGLOffsetLocation(attr)(1))(merge(common)({
                        offset: offset + ((1 * 4 | 0) * 4 | 0) | 0
                    }))();
                    Graphics_WebGL.vertexAttributeDivisor(ctx)(Graphics_WebGL.webGLOffsetLocation(attr)(1))(1)();
                    Graphics_WebGL.vertexAttributePointer(ctx)(Graphics_WebGL.webGLOffsetLocation(attr)(2))(merge(common)({
                        offset: offset + ((2 * 4 | 0) * 4 | 0) | 0
                    }))();
                    Graphics_WebGL.vertexAttributeDivisor(ctx)(Graphics_WebGL.webGLOffsetLocation(attr)(2))(1)();
                    Graphics_WebGL.vertexAttributePointer(ctx)(Graphics_WebGL.webGLOffsetLocation(attr)(3))(merge(common)({
                        offset: offset + ((3 * 4 | 0) * 4 | 0) | 0
                    }))();
                    return Graphics_WebGL.vertexAttributeDivisor(ctx)(Graphics_WebGL.webGLOffsetLocation(attr)(3))(1)();
                };
            };
        };
    };
};
var bindDataAndSetModelViewNormalMatrix = function (ctx) {
    return function (buf) {
        return function (attribs) {
            return function (perspective) {
                return function (views) {
                    var normals = map(Graphics_Matrix.normalMatrixFromView)(views);
                    var viewNormalData = Graphics_WebGL.float32ArrayConcat(Data_Array.concatMap(function (v) {
                        return [ Graphics_WebGL.mat4ToArray(v.value0), Graphics_WebGL.mat4ToArray(v.value1) ];
                    })(Data_Array.zip(views)(normals)));
                    return function __do() {
                        Graphics_WebGL.bindBuffer(ctx)(buf)(Graphics_WebGL.webGLArrayBufferTarget(ctx))();
                        Graphics_WebGL.floatBufferData(ctx)(Graphics_WebGL.webGLArrayBufferTarget(ctx))(Graphics_WebGL.webGLDynamicDraw(ctx))(viewNormalData)();
                        matrixAttributePointer(ctx)(attribs.iModelViewMatrix)(0)(16 * 8 | 0)();
                        matrixAttributePointer(ctx)(attribs.iNormalMatrix)(16 * 4 | 0)(16 * 8 | 0)();
                        return Graphics_WebGL.uniformMatrix4fv(ctx)(attribs.uProjectionMatrix)(perspective)();
                    };
                };
            };
        };
    };
};
var bindAndSetVertexUVNormal = function (ctx) {
    return function (attribs) {
        return function (model) {
            return function __do() {
                Graphics_WebGL.bindBuffer(ctx)(model.buffer)(Graphics_WebGL.webGLArrayBufferTarget(ctx))();
                Graphics_WebGL.vertexAttributePointer(ctx)(attribs.vPosition)({
                    size: 3,
                    normalized: false,
                    stride: model.stride,
                    offset: model.posOffset
                })();
                Graphics_WebGL.vertexAttributePointer(ctx)(attribs.vNormal)({
                    size: 3,
                    normalized: false,
                    stride: model.stride,
                    offset: model.normalOffset
                })();
                return Graphics_WebGL.vertexAttributePointer(ctx)(attribs.vUV)({
                    size: 2,
                    normalized: false,
                    stride: model.stride,
                    offset: model.uvOffset
                })();
            };
        };
    };
};
var withDefaultModelBindings = function (ctx) {
    return function (model) {
        return function (attribs) {
            return function (perspective) {
                return function (views) {
                    return function (cb) {
                        return Graphics_WebGL.withVAO(ctx)(Graphics_WebGL.withBuffer(ctx)(function (matrixBuffer) {
                            return function __do() {
                                bindDataAndSetModelViewNormalMatrix(ctx)(matrixBuffer)(attribs)(perspective)(views)();
                                bindAndSetVertexUVNormal(ctx)(attribs)(model)();
                                return cb();
                            };
                        }));
                    };
                };
            };
        };
    };
};
export {
    withDefaultModelBindings
};
