import * as $foreign from "./foreign.js";
var loadSound = function (v) {
    return $foreign.loadSound_(v.value0);
};
export {
    playSound
} from "./foreign.js";
export {
    loadSound
};
