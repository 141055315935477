export function loadFont_(family) {
  return function(src) {
    return function() {
      console.log(`Family: ${family} src ${src}`)
      const font = new FontFace(family, `url('${src}'`);
      font.load().then(function(font) {
        document.fonts.add(font);
      });
      return font;
    }
  }
}