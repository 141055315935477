// Generated by purs version 0.15.10
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_FoldableWithIndex from "../Data.FoldableWithIndex/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var abs = /* #__PURE__ */ Data_Ord.abs(Data_Ord.ordInt)(Data_Ring.ringInt);
var Leaf = /* #__PURE__ */ (function () {
    function Leaf() {

    };
    Leaf.value = new Leaf();
    return Leaf;
})();
var Node = /* #__PURE__ */ (function () {
    function Node(value0, value1, value2, value3, value4, value5) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
    };
    Node.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return function (value3) {
                    return function (value4) {
                        return function (value5) {
                            return new Node(value0, value1, value2, value3, value4, value5);
                        };
                    };
                };
            };
        };
    };
    return Node;
})();
var IterLeaf = /* #__PURE__ */ (function () {
    function IterLeaf() {

    };
    IterLeaf.value = new IterLeaf();
    return IterLeaf;
})();
var IterEmit = /* #__PURE__ */ (function () {
    function IterEmit(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    IterEmit.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new IterEmit(value0, value1, value2);
            };
        };
    };
    return IterEmit;
})();
var IterNode = /* #__PURE__ */ (function () {
    function IterNode(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    IterNode.create = function (value0) {
        return function (value1) {
            return new IterNode(value0, value1);
        };
    };
    return IterNode;
})();
var IterDone = /* #__PURE__ */ (function () {
    function IterDone() {

    };
    IterDone.value = new IterDone();
    return IterDone;
})();
var IterNext = /* #__PURE__ */ (function () {
    function IterNext(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    IterNext.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new IterNext(value0, value1, value2);
            };
        };
    };
    return IterNext;
})();
var Split = /* #__PURE__ */ (function () {
    function Split(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Split.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Split(value0, value1, value2);
            };
        };
    };
    return Split;
})();
var SplitLast = /* #__PURE__ */ (function () {
    function SplitLast(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    SplitLast.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new SplitLast(value0, value1, value2);
            };
        };
    };
    return SplitLast;
})();
var unsafeNode = function (k, v, l, r) {
    if (l instanceof Leaf) {
        if (r instanceof Leaf) {
            return new Node(1, 1, k, v, l, r);
        };
        if (r instanceof Node) {
            return new Node(1 + r.value0 | 0, 1 + r.value1 | 0, k, v, l, r);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 680, column 5 - line 684, column 39): " + [ r.constructor.name ]);
    };
    if (l instanceof Node) {
        if (r instanceof Leaf) {
            return new Node(1 + l.value0 | 0, 1 + l.value1 | 0, k, v, l, r);
        };
        if (r instanceof Node) {
            return new Node(1 + (function () {
                var $277 = l.value0 > r.value0;
                if ($277) {
                    return l.value0;
                };
                return r.value0;
            })() | 0, (1 + l.value1 | 0) + r.value1 | 0, k, v, l, r);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 686, column 5 - line 690, column 68): " + [ r.constructor.name ]);
    };
    throw new Error("Failed pattern match at Data.Map.Internal (line 678, column 32 - line 690, column 68): " + [ l.constructor.name ]);
};
var toMapIter = /* #__PURE__ */ (function () {
    return Data_Function.flip(IterNode.create)(IterLeaf.value);
})();
var stepWith = function (f) {
    return function (next) {
        return function (done) {
            var go = function ($copy_v) {
                var $tco_done = false;
                var $tco_result;
                function $tco_loop(v) {
                    if (v instanceof IterLeaf) {
                        $tco_done = true;
                        return done(Data_Unit.unit);
                    };
                    if (v instanceof IterEmit) {
                        $tco_done = true;
                        return next(v.value0, v.value1, v.value2);
                    };
                    if (v instanceof IterNode) {
                        $copy_v = f(v.value1)(v.value0);
                        return;
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 918, column 8 - line 924, column 20): " + [ v.constructor.name ]);
                };
                while (!$tco_done) {
                    $tco_result = $tco_loop($copy_v);
                };
                return $tco_result;
            };
            return go;
        };
    };
};
var size = function (v) {
    if (v instanceof Leaf) {
        return 0;
    };
    if (v instanceof Node) {
        return v.value1;
    };
    throw new Error("Failed pattern match at Data.Map.Internal (line 616, column 8 - line 618, column 24): " + [ v.constructor.name ]);
};
var singleton = function (k) {
    return function (v) {
        return new Node(1, 1, k, v, Leaf.value, Leaf.value);
    };
};
var unsafeBalancedNode = /* #__PURE__ */ (function () {
    var height = function (v) {
        if (v instanceof Leaf) {
            return 0;
        };
        if (v instanceof Node) {
            return v.value0;
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 735, column 12 - line 737, column 26): " + [ v.constructor.name ]);
    };
    var rotateLeft = function (k, v, l, rk, rv, rl, rr) {
        if (rl instanceof Node && rl.value0 > height(rr)) {
            return unsafeNode(rl.value2, rl.value3, unsafeNode(k, v, l, rl.value4), unsafeNode(rk, rv, rl.value5, rr));
        };
        return unsafeNode(rk, rv, unsafeNode(k, v, l, rl), rr);
    };
    var rotateRight = function (k, v, lk, lv, ll, lr, r) {
        if (lr instanceof Node && height(ll) <= lr.value0) {
            return unsafeNode(lr.value2, lr.value3, unsafeNode(lk, lv, ll, lr.value4), unsafeNode(k, v, lr.value5, r));
        };
        return unsafeNode(lk, lv, ll, unsafeNode(k, v, lr, r));
    };
    return function (k, v, l, r) {
        if (l instanceof Leaf) {
            if (r instanceof Leaf) {
                return singleton(k)(v);
            };
            if (r instanceof Node && r.value0 > 1) {
                return rotateLeft(k, v, l, r.value2, r.value3, r.value4, r.value5);
            };
            return unsafeNode(k, v, l, r);
        };
        if (l instanceof Node) {
            if (r instanceof Node) {
                if (r.value0 > (l.value0 + 1 | 0)) {
                    return rotateLeft(k, v, l, r.value2, r.value3, r.value4, r.value5);
                };
                if (l.value0 > (r.value0 + 1 | 0)) {
                    return rotateRight(k, v, l.value2, l.value3, l.value4, l.value5, r);
                };
            };
            if (r instanceof Leaf && l.value0 > 1) {
                return rotateRight(k, v, l.value2, l.value3, l.value4, l.value5, r);
            };
            return unsafeNode(k, v, l, r);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 695, column 40 - line 716, column 34): " + [ l.constructor.name ]);
    };
})();
var $lazy_unsafeSplit = /* #__PURE__ */ $runtime_lazy("unsafeSplit", "Data.Map.Internal", function () {
    return function (comp, k, m) {
        if (m instanceof Leaf) {
            return new Split(Data_Maybe.Nothing.value, Leaf.value, Leaf.value);
        };
        if (m instanceof Node) {
            var v = comp(k)(m.value2);
            if (v instanceof Data_Ordering.LT) {
                var v1 = $lazy_unsafeSplit(771)(comp, k, m.value4);
                return new Split(v1.value0, v1.value1, unsafeBalancedNode(m.value2, m.value3, v1.value2, m.value5));
            };
            if (v instanceof Data_Ordering.GT) {
                var v1 = $lazy_unsafeSplit(774)(comp, k, m.value5);
                return new Split(v1.value0, unsafeBalancedNode(m.value2, m.value3, m.value4, v1.value1), v1.value2);
            };
            if (v instanceof Data_Ordering.EQ) {
                return new Split(new Data_Maybe.Just(m.value3), m.value4, m.value5);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 769, column 5 - line 777, column 30): " + [ v.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 765, column 34 - line 777, column 30): " + [ m.constructor.name ]);
    };
});
var unsafeSplit = /* #__PURE__ */ $lazy_unsafeSplit(764);
var $lazy_unsafeSplitLast = /* #__PURE__ */ $runtime_lazy("unsafeSplitLast", "Data.Map.Internal", function () {
    return function (k, v, l, r) {
        if (r instanceof Leaf) {
            return new SplitLast(k, v, l);
        };
        if (r instanceof Node) {
            var v1 = $lazy_unsafeSplitLast(757)(r.value2, r.value3, r.value4, r.value5);
            return new SplitLast(v1.value0, v1.value1, unsafeBalancedNode(k, v, l, v1.value2));
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 754, column 37 - line 758, column 57): " + [ r.constructor.name ]);
    };
});
var unsafeSplitLast = /* #__PURE__ */ $lazy_unsafeSplitLast(753);
var unsafeJoinNodes = function (v, v1) {
    if (v instanceof Leaf) {
        return v1;
    };
    if (v instanceof Node) {
        var v2 = unsafeSplitLast(v.value2, v.value3, v.value4, v.value5);
        return unsafeBalancedNode(v2.value0, v2.value1, v2.value2, v1);
    };
    throw new Error("Failed pattern match at Data.Map.Internal (line 742, column 25 - line 746, column 38): " + [ v.constructor.name, v1.constructor.name ]);
};
var $lazy_unsafeDifference = /* #__PURE__ */ $runtime_lazy("unsafeDifference", "Data.Map.Internal", function () {
    return function (comp, l, r) {
        if (l instanceof Leaf) {
            return Leaf.value;
        };
        if (r instanceof Leaf) {
            return l;
        };
        if (r instanceof Node) {
            var v = unsafeSplit(comp, r.value2, l);
            var l$prime = $lazy_unsafeDifference(819)(comp, v.value1, r.value4);
            var r$prime = $lazy_unsafeDifference(820)(comp, v.value2, r.value5);
            return unsafeJoinNodes(l$prime, r$prime);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 814, column 39 - line 821, column 33): " + [ l.constructor.name, r.constructor.name ]);
    };
});
var unsafeDifference = /* #__PURE__ */ $lazy_unsafeDifference(813);
var $lazy_unsafeIntersectionWith = /* #__PURE__ */ $runtime_lazy("unsafeIntersectionWith", "Data.Map.Internal", function () {
    return function (comp, app, l, r) {
        if (l instanceof Leaf) {
            return Leaf.value;
        };
        if (r instanceof Leaf) {
            return Leaf.value;
        };
        if (r instanceof Node) {
            var v = unsafeSplit(comp, r.value2, l);
            var l$prime = $lazy_unsafeIntersectionWith(803)(comp, app, v.value1, r.value4);
            var r$prime = $lazy_unsafeIntersectionWith(804)(comp, app, v.value2, r.value5);
            if (v.value0 instanceof Data_Maybe.Just) {
                return unsafeBalancedNode(r.value2, app(v.value0.value0)(r.value3), l$prime, r$prime);
            };
            if (v.value0 instanceof Data_Maybe.Nothing) {
                return unsafeJoinNodes(l$prime, r$prime);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 805, column 5 - line 809, column 37): " + [ v.value0.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 798, column 49 - line 809, column 37): " + [ l.constructor.name, r.constructor.name ]);
    };
});
var unsafeIntersectionWith = /* #__PURE__ */ $lazy_unsafeIntersectionWith(797);
var $lazy_unsafeUnionWith = /* #__PURE__ */ $runtime_lazy("unsafeUnionWith", "Data.Map.Internal", function () {
    return function (comp, app, l, r) {
        if (l instanceof Leaf) {
            return r;
        };
        if (r instanceof Leaf) {
            return l;
        };
        if (r instanceof Node) {
            var v = unsafeSplit(comp, r.value2, l);
            var l$prime = $lazy_unsafeUnionWith(787)(comp, app, v.value1, r.value4);
            var r$prime = $lazy_unsafeUnionWith(788)(comp, app, v.value2, r.value5);
            if (v.value0 instanceof Data_Maybe.Just) {
                return unsafeBalancedNode(r.value2, app(v.value0.value0)(r.value3), l$prime, r$prime);
            };
            if (v.value0 instanceof Data_Maybe.Nothing) {
                return unsafeBalancedNode(r.value2, r.value3, l$prime, r$prime);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 789, column 5 - line 793, column 46): " + [ v.value0.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 782, column 42 - line 793, column 46): " + [ l.constructor.name, r.constructor.name ]);
    };
});
var unsafeUnionWith = /* #__PURE__ */ $lazy_unsafeUnionWith(781);
var unionWith = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (app) {
        return function (m1) {
            return function (m2) {
                return unsafeUnionWith(compare, app, m1, m2);
            };
        };
    };
};
var union = function (dictOrd) {
    return unionWith(dictOrd)(Data_Function["const"]);
};
var update = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (f) {
        return function (k) {
            var go = function (v) {
                if (v instanceof Leaf) {
                    return Leaf.value;
                };
                if (v instanceof Node) {
                    var v1 = compare(k)(v.value2);
                    if (v1 instanceof Data_Ordering.LT) {
                        return unsafeBalancedNode(v.value2, v.value3, go(v.value4), v.value5);
                    };
                    if (v1 instanceof Data_Ordering.GT) {
                        return unsafeBalancedNode(v.value2, v.value3, v.value4, go(v.value5));
                    };
                    if (v1 instanceof Data_Ordering.EQ) {
                        var v2 = f(v.value3);
                        if (v2 instanceof Data_Maybe.Nothing) {
                            return unsafeJoinNodes(v.value4, v.value5);
                        };
                        if (v2 instanceof Data_Maybe.Just) {
                            return new Node(v.value0, v.value1, v.value2, v2.value0, v.value4, v.value5);
                        };
                        throw new Error("Failed pattern match at Data.Map.Internal (line 529, column 11 - line 533, column 38): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 525, column 7 - line 533, column 38): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 522, column 8 - line 533, column 38): " + [ v.constructor.name ]);
            };
            return go;
        };
    };
};
var showTree = function (dictShow) {
    var show1 = Data_Show.show(dictShow);
    return function (dictShow1) {
        var show2 = Data_Show.show(dictShow1);
        var go = function (ind) {
            return function (v) {
                if (v instanceof Leaf) {
                    return ind + "Leaf";
                };
                if (v instanceof Node) {
                    return ind + ("[" + (show(v.value0) + ("] " + (show1(v.value2) + (" => " + (show2(v.value3) + "\x0a")))))) + (go(ind + "    ")(v.value4) + "\x0a" + go(ind + "    ")(v.value5));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 231, column 12 - line 236, column 34): " + [ v.constructor.name ]);
            };
        };
        return go("");
    };
};
var semigroupMap = function () {
    return function (dictOrd) {
        var unionWith1 = unionWith(dictOrd);
        return function (dictSemigroup) {
            return {
                append: unionWith1(Data_Semigroup.append(dictSemigroup))
            };
        };
    };
};
var semigroupMap1 = /* #__PURE__ */ semigroupMap();
var pop = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        return function (m) {
            var v = unsafeSplit(compare, k, m);
            return map(function (a) {
                return new Data_Tuple.Tuple(a, unsafeJoinNodes(v.value1, v.value2));
            })(v.value0);
        };
    };
};
var member = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function ($copy_v) {
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v) {
                if (v instanceof Leaf) {
                    $tco_done = true;
                    return false;
                };
                if (v instanceof Node) {
                    var v1 = compare(k)(v.value2);
                    if (v1 instanceof Data_Ordering.LT) {
                        $copy_v = v.value4;
                        return;
                    };
                    if (v1 instanceof Data_Ordering.GT) {
                        $copy_v = v.value5;
                        return;
                    };
                    if (v1 instanceof Data_Ordering.EQ) {
                        $tco_done = true;
                        return true;
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 457, column 7 - line 460, column 19): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 454, column 8 - line 460, column 19): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($copy_v);
            };
            return $tco_result;
        };
        return go;
    };
};
var mapMaybeWithKey = function (dictOrd) {
    return function (f) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Leaf.value;
            };
            if (v instanceof Node) {
                var v2 = f(v.value2)(v.value3);
                if (v2 instanceof Data_Maybe.Just) {
                    return unsafeBalancedNode(v.value2, v2.value0, go(v.value4), go(v.value5));
                };
                if (v2 instanceof Data_Maybe.Nothing) {
                    return unsafeJoinNodes(go(v.value4), go(v.value5));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 659, column 7 - line 663, column 47): " + [ v2.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 656, column 8 - line 663, column 47): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var mapMaybe = function (dictOrd) {
    var $763 = mapMaybeWithKey(dictOrd);
    return function ($764) {
        return $763(Data_Function["const"]($764));
    };
};
var lookupLE = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v instanceof Node) {
                var v1 = compare(k)(v.value2);
                if (v1 instanceof Data_Ordering.LT) {
                    return go(v.value4);
                };
                if (v1 instanceof Data_Ordering.GT) {
                    var v2 = go(v.value5);
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Maybe.Just({
                            key: v.value2,
                            value: v.value3
                        });
                    };
                    return v2;
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    return new Data_Maybe.Just({
                        key: v.value2,
                        value: v.value3
                    });
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 293, column 7 - line 300, column 38): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 290, column 8 - line 300, column 38): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var lookupGE = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v instanceof Node) {
                var v1 = compare(k)(v.value2);
                if (v1 instanceof Data_Ordering.LT) {
                    var v2 = go(v.value4);
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Maybe.Just({
                            key: v.value2,
                            value: v.value3
                        });
                    };
                    return v2;
                };
                if (v1 instanceof Data_Ordering.GT) {
                    return go(v.value5);
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    return new Data_Maybe.Just({
                        key: v.value2,
                        value: v.value3
                    });
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 325, column 7 - line 331, column 42): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 322, column 8 - line 331, column 42): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var lookup = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function ($copy_v) {
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v) {
                if (v instanceof Leaf) {
                    $tco_done = true;
                    return Data_Maybe.Nothing.value;
                };
                if (v instanceof Node) {
                    var v1 = compare(k)(v.value2);
                    if (v1 instanceof Data_Ordering.LT) {
                        $copy_v = v.value4;
                        return;
                    };
                    if (v1 instanceof Data_Ordering.GT) {
                        $copy_v = v.value5;
                        return;
                    };
                    if (v1 instanceof Data_Ordering.EQ) {
                        $tco_done = true;
                        return new Data_Maybe.Just(v.value3);
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 281, column 7 - line 284, column 22): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 278, column 8 - line 284, column 22): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($copy_v);
            };
            return $tco_result;
        };
        return go;
    };
};
var iterMapU = function (iter) {
    return function (v) {
        if (v instanceof Leaf) {
            return iter;
        };
        if (v instanceof Node) {
            if (v.value4 instanceof Leaf) {
                if (v.value5 instanceof Leaf) {
                    return new IterEmit(v.value2, v.value3, iter);
                };
                return new IterEmit(v.value2, v.value3, new IterNode(v.value5, iter));
            };
            if (v.value5 instanceof Leaf) {
                return new IterEmit(v.value2, v.value3, new IterNode(v.value4, iter));
            };
            return new IterEmit(v.value2, v.value3, new IterNode(v.value4, new IterNode(v.value5, iter)));
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 951, column 17 - line 966, column 56): " + [ v.constructor.name ]);
    };
};
var stepUnorderedCps = /* #__PURE__ */ stepWith(iterMapU);
var stepUnfoldrUnordered = /* #__PURE__ */ (function () {
    var step = function (k, v, next) {
        return new Data_Maybe.Just(new Data_Tuple.Tuple(new Data_Tuple.Tuple(k, v), next));
    };
    return stepUnorderedCps(step)(function (v) {
        return Data_Maybe.Nothing.value;
    });
})();
var toUnfoldableUnordered = function (dictUnfoldable) {
    var $765 = Data_Unfoldable.unfoldr(dictUnfoldable)(stepUnfoldrUnordered);
    return function ($766) {
        return $765(toMapIter($766));
    };
};
var stepUnordered = /* #__PURE__ */ (function () {
    return stepUnorderedCps(function (k, v, next) {
        return new IterNext(k, v, next);
    })(Data_Function["const"](IterDone.value));
})();
var iterMapR = /* #__PURE__ */ (function () {
    var go = function ($copy_iter) {
        return function ($copy_v) {
            var $tco_var_iter = $copy_iter;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(iter, v) {
                if (v instanceof Leaf) {
                    $tco_done = true;
                    return iter;
                };
                if (v instanceof Node) {
                    if (v.value5 instanceof Leaf) {
                        $tco_var_iter = new IterEmit(v.value2, v.value3, iter);
                        $copy_v = v.value4;
                        return;
                    };
                    $tco_var_iter = new IterEmit(v.value2, v.value3, new IterNode(v.value4, iter));
                    $copy_v = v.value5;
                    return;
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 941, column 13 - line 948, column 48): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_iter, $copy_v);
            };
            return $tco_result;
        };
    };
    return go;
})();
var stepDescCps = /* #__PURE__ */ stepWith(iterMapR);
var stepDesc = /* #__PURE__ */ (function () {
    return stepDescCps(function (k, v, next) {
        return new IterNext(k, v, next);
    })(Data_Function["const"](IterDone.value));
})();
var iterMapL = /* #__PURE__ */ (function () {
    var go = function ($copy_iter) {
        return function ($copy_v) {
            var $tco_var_iter = $copy_iter;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(iter, v) {
                if (v instanceof Leaf) {
                    $tco_done = true;
                    return iter;
                };
                if (v instanceof Node) {
                    if (v.value5 instanceof Leaf) {
                        $tco_var_iter = new IterEmit(v.value2, v.value3, iter);
                        $copy_v = v.value4;
                        return;
                    };
                    $tco_var_iter = new IterEmit(v.value2, v.value3, new IterNode(v.value5, iter));
                    $copy_v = v.value4;
                    return;
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 929, column 13 - line 936, column 48): " + [ v.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_iter, $copy_v);
            };
            return $tco_result;
        };
    };
    return go;
})();
var stepAscCps = /* #__PURE__ */ stepWith(iterMapL);
var stepAsc = /* #__PURE__ */ (function () {
    return stepAscCps(function (k, v, next) {
        return new IterNext(k, v, next);
    })(Data_Function["const"](IterDone.value));
})();
var eqMapIter = function (dictEq) {
    var eq1 = Data_Eq.eq(dictEq);
    return function (dictEq1) {
        var eq2 = Data_Eq.eq(dictEq1);
        return {
            eq: (function () {
                var go = function ($copy_a) {
                    return function ($copy_b) {
                        var $tco_var_a = $copy_a;
                        var $tco_done = false;
                        var $tco_result;
                        function $tco_loop(a, b) {
                            var v = stepAsc(a);
                            if (v instanceof IterNext) {
                                var v2 = stepAsc(b);
                                if (v2 instanceof IterNext && (eq1(v.value0)(v2.value0) && eq2(v.value1)(v2.value1))) {
                                    $tco_var_a = v.value2;
                                    $copy_b = v2.value2;
                                    return;
                                };
                                $tco_done = true;
                                return false;
                            };
                            if (v instanceof IterDone) {
                                $tco_done = true;
                                return true;
                            };
                            throw new Error("Failed pattern match at Data.Map.Internal (line 837, column 14 - line 846, column 13): " + [ v.constructor.name ]);
                        };
                        while (!$tco_done) {
                            $tco_result = $tco_loop($tco_var_a, $copy_b);
                        };
                        return $tco_result;
                    };
                };
                return go;
            })()
        };
    };
};
var ordMapIter = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    var eqMapIter1 = eqMapIter(dictOrd.Eq0());
    return function (dictOrd1) {
        var compare1 = Data_Ord.compare(dictOrd1);
        var eqMapIter2 = eqMapIter1(dictOrd1.Eq0());
        return {
            compare: (function () {
                var go = function ($copy_a) {
                    return function ($copy_b) {
                        var $tco_var_a = $copy_a;
                        var $tco_done = false;
                        var $tco_result;
                        function $tco_loop(a, b) {
                            var v = stepAsc(b);
                            var v1 = stepAsc(a);
                            if (v1 instanceof IterNext && v instanceof IterNext) {
                                var v3 = compare(v1.value0)(v.value0);
                                if (v3 instanceof Data_Ordering.EQ) {
                                    var v4 = compare1(v1.value1)(v.value1);
                                    if (v4 instanceof Data_Ordering.EQ) {
                                        $tco_var_a = v1.value2;
                                        $copy_b = v.value2;
                                        return;
                                    };
                                    $tco_done = true;
                                    return v4;
                                };
                                $tco_done = true;
                                return v3;
                            };
                            if (v1 instanceof IterDone) {
                                if (v instanceof IterDone) {
                                    $tco_done = true;
                                    return Data_Ordering.EQ.value;
                                };
                                $tco_done = true;
                                return Data_Ordering.LT.value;
                            };
                            if (v instanceof IterDone) {
                                $tco_done = true;
                                return Data_Ordering.GT.value;
                            };
                            throw new Error("Failed pattern match at Data.Map.Internal (line 851, column 14 - line 869, column 11): " + [ v1.constructor.name, v.constructor.name ]);
                        };
                        while (!$tco_done) {
                            $tco_result = $tco_loop($tco_var_a, $copy_b);
                        };
                        return $tco_result;
                    };
                };
                return go;
            })(),
            Eq0: function () {
                return eqMapIter2;
            }
        };
    };
};
var stepUnfoldr = /* #__PURE__ */ (function () {
    var step = function (k, v, next) {
        return new Data_Maybe.Just(new Data_Tuple.Tuple(new Data_Tuple.Tuple(k, v), next));
    };
    return stepAscCps(step)(function (v) {
        return Data_Maybe.Nothing.value;
    });
})();
var toUnfoldable = function (dictUnfoldable) {
    var $767 = Data_Unfoldable.unfoldr(dictUnfoldable)(stepUnfoldr);
    return function ($768) {
        return $767(toMapIter($768));
    };
};
var toUnfoldable1 = /* #__PURE__ */ toUnfoldable(Data_Unfoldable.unfoldableArray);
var showMap = function (dictShow) {
    var showTuple = Data_Tuple.showTuple(dictShow);
    return function (dictShow1) {
        var show1 = Data_Show.show(Data_Show.showArray(showTuple(dictShow1)));
        return {
            show: function (as) {
                return "(fromFoldable " + (show1(toUnfoldable1(as)) + ")");
            }
        };
    };
};
var isSubmap = function (dictOrd) {
    var lookup1 = lookup(dictOrd);
    return function (dictEq) {
        var eq1 = Data_Eq.eq(dictEq);
        var go = function (m1) {
            return function (m2) {
                if (m1 instanceof Leaf) {
                    return true;
                };
                if (m1 instanceof Node) {
                    var v1 = lookup1(m1.value2)(m2);
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        return eq1(m1.value3)(v1.value0) && (go(m1.value4)(m2) && go(m1.value5)(m2));
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 609, column 7 - line 612, column 40): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 606, column 14 - line 612, column 40): " + [ m1.constructor.name ]);
            };
        };
        return go;
    };
};
var isEmpty = function (v) {
    if (v instanceof Leaf) {
        return true;
    };
    return false;
};
var intersectionWith = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (app) {
        return function (m1) {
            return function (m2) {
                return unsafeIntersectionWith(compare, app, m1, m2);
            };
        };
    };
};
var intersection = function (dictOrd) {
    return intersectionWith(dictOrd)(Data_Function["const"]);
};
var insertWith = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (app) {
        return function (k) {
            return function (v) {
                var go = function (v1) {
                    if (v1 instanceof Leaf) {
                        return singleton(k)(v);
                    };
                    if (v1 instanceof Node) {
                        var v2 = compare(k)(v1.value2);
                        if (v2 instanceof Data_Ordering.LT) {
                            return unsafeBalancedNode(v1.value2, v1.value3, go(v1.value4), v1.value5);
                        };
                        if (v2 instanceof Data_Ordering.GT) {
                            return unsafeBalancedNode(v1.value2, v1.value3, v1.value4, go(v1.value5));
                        };
                        if (v2 instanceof Data_Ordering.EQ) {
                            return new Node(v1.value0, v1.value1, k, app(v1.value3)(v), v1.value4, v1.value5);
                        };
                        throw new Error("Failed pattern match at Data.Map.Internal (line 484, column 7 - line 487, column 44): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 481, column 8 - line 487, column 44): " + [ v1.constructor.name ]);
                };
                return go;
            };
        };
    };
};
var insert = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        return function (v) {
            var go = function (v1) {
                if (v1 instanceof Leaf) {
                    return singleton(k)(v);
                };
                if (v1 instanceof Node) {
                    var v2 = compare(k)(v1.value2);
                    if (v2 instanceof Data_Ordering.LT) {
                        return unsafeBalancedNode(v1.value2, v1.value3, go(v1.value4), v1.value5);
                    };
                    if (v2 instanceof Data_Ordering.GT) {
                        return unsafeBalancedNode(v1.value2, v1.value3, v1.value4, go(v1.value5));
                    };
                    if (v2 instanceof Data_Ordering.EQ) {
                        return new Node(v1.value0, v1.value1, k, v, v1.value4, v1.value5);
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 469, column 7 - line 472, column 35): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 466, column 8 - line 472, column 35): " + [ v1.constructor.name ]);
            };
            return go;
        };
    };
};
var functorMap = {
    map: function (f) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Leaf.value;
            };
            if (v instanceof Node) {
                return new Node(v.value0, v.value1, v.value2, f(v.value3), go(v.value4), go(v.value5));
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 145, column 10 - line 148, column 39): " + [ v.constructor.name ]);
        };
        return go;
    }
};
var functorWithIndexMap = {
    mapWithIndex: function (f) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Leaf.value;
            };
            if (v instanceof Node) {
                return new Node(v.value0, v.value1, v.value2, f(v.value2)(v.value3), go(v.value4), go(v.value5));
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 153, column 10 - line 156, column 41): " + [ v.constructor.name ]);
        };
        return go;
    },
    Functor0: function () {
        return functorMap;
    }
};
var foldableMap = {
    foldr: function (f) {
        return function (z) {
            var $lazy_go = $runtime_lazy("go", "Data.Map.Internal", function () {
                return function (m$prime, z$prime) {
                    if (m$prime instanceof Leaf) {
                        return z$prime;
                    };
                    if (m$prime instanceof Node) {
                        return $lazy_go(170)(m$prime.value4, f(m$prime.value3)($lazy_go(170)(m$prime.value5, z$prime)));
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 167, column 26 - line 170, column 43): " + [ m$prime.constructor.name ]);
                };
            });
            var go = $lazy_go(167);
            return function (m) {
                return go(m, z);
            };
        };
    },
    foldl: function (f) {
        return function (z) {
            var $lazy_go = $runtime_lazy("go", "Data.Map.Internal", function () {
                return function (z$prime, m$prime) {
                    if (m$prime instanceof Leaf) {
                        return z$prime;
                    };
                    if (m$prime instanceof Node) {
                        return $lazy_go(176)(f($lazy_go(176)(z$prime, m$prime.value4))(m$prime.value3), m$prime.value5);
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 173, column 26 - line 176, column 43): " + [ m$prime.constructor.name ]);
                };
            });
            var go = $lazy_go(173);
            return function (m) {
                return go(z, m);
            };
        };
    },
    foldMap: function (dictMonoid) {
        var mempty = Data_Monoid.mempty(dictMonoid);
        var append1 = Data_Semigroup.append(dictMonoid.Semigroup0());
        return function (f) {
            var go = function (v) {
                if (v instanceof Leaf) {
                    return mempty;
                };
                if (v instanceof Node) {
                    return append1(go(v.value4))(append1(f(v.value3))(go(v.value5)));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 179, column 10 - line 182, column 28): " + [ v.constructor.name ]);
            };
            return go;
        };
    }
};
var foldableWithIndexMap = {
    foldrWithIndex: function (f) {
        return function (z) {
            var $lazy_go = $runtime_lazy("go", "Data.Map.Internal", function () {
                return function (m$prime, z$prime) {
                    if (m$prime instanceof Leaf) {
                        return z$prime;
                    };
                    if (m$prime instanceof Node) {
                        return $lazy_go(190)(m$prime.value4, f(m$prime.value2)(m$prime.value3)($lazy_go(190)(m$prime.value5, z$prime)));
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 187, column 26 - line 190, column 45): " + [ m$prime.constructor.name ]);
                };
            });
            var go = $lazy_go(187);
            return function (m) {
                return go(m, z);
            };
        };
    },
    foldlWithIndex: function (f) {
        return function (z) {
            var $lazy_go = $runtime_lazy("go", "Data.Map.Internal", function () {
                return function (z$prime, m$prime) {
                    if (m$prime instanceof Leaf) {
                        return z$prime;
                    };
                    if (m$prime instanceof Node) {
                        return $lazy_go(196)(f(m$prime.value2)($lazy_go(196)(z$prime, m$prime.value4))(m$prime.value3), m$prime.value5);
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 193, column 26 - line 196, column 45): " + [ m$prime.constructor.name ]);
                };
            });
            var go = $lazy_go(193);
            return function (m) {
                return go(z, m);
            };
        };
    },
    foldMapWithIndex: function (dictMonoid) {
        var mempty = Data_Monoid.mempty(dictMonoid);
        var append1 = Data_Semigroup.append(dictMonoid.Semigroup0());
        return function (f) {
            var go = function (v) {
                if (v instanceof Leaf) {
                    return mempty;
                };
                if (v instanceof Node) {
                    return append1(go(v.value4))(append1(f(v.value2)(v.value3))(go(v.value5)));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 199, column 10 - line 202, column 30): " + [ v.constructor.name ]);
            };
            return go;
        };
    },
    Foldable0: function () {
        return foldableMap;
    }
};
var keys = /* #__PURE__ */ (function () {
    return Data_FoldableWithIndex.foldrWithIndex(foldableWithIndexMap)(function (k) {
        return function (v) {
            return function (acc) {
                return new Data_List_Types.Cons(k, acc);
            };
        };
    })(Data_List_Types.Nil.value);
})();
var traversableMap = {
    traverse: function (dictApplicative) {
        var pure = Control_Applicative.pure(dictApplicative);
        var Apply0 = dictApplicative.Apply0();
        var apply = Control_Apply.apply(Apply0);
        var map1 = Data_Functor.map(Apply0.Functor0());
        return function (f) {
            var go = function (v) {
                if (v instanceof Leaf) {
                    return pure(Leaf.value);
                };
                if (v instanceof Node) {
                    return apply(apply(map1(function (l$prime) {
                        return function (v$prime) {
                            return function (r$prime) {
                                return new Node(v.value0, v.value1, v.value2, v$prime, l$prime, r$prime);
                            };
                        };
                    })(go(v.value4)))(f(v.value3)))(go(v.value5));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 207, column 10 - line 213, column 19): " + [ v.constructor.name ]);
            };
            return go;
        };
    },
    sequence: function (dictApplicative) {
        return Data_Traversable.traverse(traversableMap)(dictApplicative)(identity);
    },
    Functor0: function () {
        return functorMap;
    },
    Foldable1: function () {
        return foldableMap;
    }
};
var traversableWithIndexMap = {
    traverseWithIndex: function (dictApplicative) {
        var pure = Control_Applicative.pure(dictApplicative);
        var Apply0 = dictApplicative.Apply0();
        var apply = Control_Apply.apply(Apply0);
        var map1 = Data_Functor.map(Apply0.Functor0());
        return function (f) {
            var go = function (v) {
                if (v instanceof Leaf) {
                    return pure(Leaf.value);
                };
                if (v instanceof Node) {
                    return apply(apply(map1(function (l$prime) {
                        return function (v$prime) {
                            return function (r$prime) {
                                return new Node(v.value0, v.value1, v.value2, v$prime, l$prime, r$prime);
                            };
                        };
                    })(go(v.value4)))(f(v.value2)(v.value3)))(go(v.value5));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 219, column 10 - line 225, column 19): " + [ v.constructor.name ]);
            };
            return go;
        };
    },
    FunctorWithIndex0: function () {
        return functorWithIndexMap;
    },
    FoldableWithIndex1: function () {
        return foldableWithIndexMap;
    },
    Traversable2: function () {
        return traversableMap;
    }
};
var values = /* #__PURE__ */ (function () {
    return Data_Foldable.foldr(foldableMap)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
})();
var foldSubmapBy = function (dictOrd) {
    var lessThan1 = Data_Ord.lessThan(dictOrd);
    var greaterThan1 = Data_Ord.greaterThan(dictOrd);
    var lessThanOrEq1 = Data_Ord.lessThanOrEq(dictOrd);
    return function (appendFn) {
        return function (memptyValue) {
            return function (kmin) {
                return function (kmax) {
                    return function (f) {
                        var tooSmall = (function () {
                            if (kmin instanceof Data_Maybe.Just) {
                                return function (k) {
                                    return lessThan1(k)(kmin.value0);
                                };
                            };
                            if (kmin instanceof Data_Maybe.Nothing) {
                                return Data_Function["const"](false);
                            };
                            throw new Error("Failed pattern match at Data.Map.Internal (line 387, column 7 - line 391, column 22): " + [ kmin.constructor.name ]);
                        })();
                        var tooLarge = (function () {
                            if (kmax instanceof Data_Maybe.Just) {
                                return function (k) {
                                    return greaterThan1(k)(kmax.value0);
                                };
                            };
                            if (kmax instanceof Data_Maybe.Nothing) {
                                return Data_Function["const"](false);
                            };
                            throw new Error("Failed pattern match at Data.Map.Internal (line 394, column 7 - line 398, column 22): " + [ kmax.constructor.name ]);
                        })();
                        var inBounds = (function () {
                            if (kmin instanceof Data_Maybe.Just && kmax instanceof Data_Maybe.Just) {
                                return function (k) {
                                    return lessThanOrEq1(kmin.value0)(k) && lessThanOrEq1(k)(kmax.value0);
                                };
                            };
                            if (kmin instanceof Data_Maybe.Just && kmax instanceof Data_Maybe.Nothing) {
                                return function (k) {
                                    return lessThanOrEq1(kmin.value0)(k);
                                };
                            };
                            if (kmin instanceof Data_Maybe.Nothing && kmax instanceof Data_Maybe.Just) {
                                return function (k) {
                                    return lessThanOrEq1(k)(kmax.value0);
                                };
                            };
                            if (kmin instanceof Data_Maybe.Nothing && kmax instanceof Data_Maybe.Nothing) {
                                return Data_Function["const"](true);
                            };
                            throw new Error("Failed pattern match at Data.Map.Internal (line 401, column 7 - line 409, column 21): " + [ kmin.constructor.name, kmax.constructor.name ]);
                        })();
                        var go = function (v) {
                            if (v instanceof Leaf) {
                                return memptyValue;
                            };
                            if (v instanceof Node) {
                                return appendFn(appendFn((function () {
                                    var $640 = tooSmall(v.value2);
                                    if ($640) {
                                        return memptyValue;
                                    };
                                    return go(v.value4);
                                })())((function () {
                                    var $641 = inBounds(v.value2);
                                    if ($641) {
                                        return f(v.value2)(v.value3);
                                    };
                                    return memptyValue;
                                })()))((function () {
                                    var $642 = tooLarge(v.value2);
                                    if ($642) {
                                        return memptyValue;
                                    };
                                    return go(v.value5);
                                })());
                            };
                            throw new Error("Failed pattern match at Data.Map.Internal (line 411, column 10 - line 417, column 66): " + [ v.constructor.name ]);
                        };
                        return go;
                    };
                };
            };
        };
    };
};
var foldSubmap = function (dictOrd) {
    var foldSubmapBy1 = foldSubmapBy(dictOrd);
    return function (dictMonoid) {
        return foldSubmapBy1(Data_Semigroup.append(dictMonoid.Semigroup0()))(Data_Monoid.mempty(dictMonoid));
    };
};
var findMin = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;
    function $tco_loop(v) {
        if (v instanceof Leaf) {
            $tco_done = true;
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Node) {
            if (v.value4 instanceof Leaf) {
                $tco_done = true;
                return new Data_Maybe.Just({
                    key: v.value2,
                    value: v.value3
                });
            };
            $copy_v = v.value4;
            return;
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 359, column 11 - line 364, column 21): " + [ v.constructor.name ]);
    };
    while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
    };
    return $tco_result;
};
var lookupGT = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v instanceof Node) {
                var v1 = compare(k)(v.value2);
                if (v1 instanceof Data_Ordering.LT) {
                    var v2 = go(v.value4);
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Maybe.Just({
                            key: v.value2,
                            value: v.value3
                        });
                    };
                    return v2;
                };
                if (v1 instanceof Data_Ordering.GT) {
                    return go(v.value5);
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    return findMin(v.value5);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 340, column 7 - line 346, column 25): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 337, column 8 - line 346, column 25): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var findMax = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;
    function $tco_loop(v) {
        if (v instanceof Leaf) {
            $tco_done = true;
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Node) {
            if (v.value5 instanceof Leaf) {
                $tco_done = true;
                return new Data_Maybe.Just({
                    key: v.value2,
                    value: v.value3
                });
            };
            $copy_v = v.value5;
            return;
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 350, column 11 - line 355, column 21): " + [ v.constructor.name ]);
    };
    while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
    };
    return $tco_result;
};
var lookupLT = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Data_Maybe.Nothing.value;
            };
            if (v instanceof Node) {
                var v1 = compare(k)(v.value2);
                if (v1 instanceof Data_Ordering.LT) {
                    return go(v.value4);
                };
                if (v1 instanceof Data_Ordering.GT) {
                    var v2 = go(v.value5);
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Maybe.Just({
                            key: v.value2,
                            value: v.value3
                        });
                    };
                    return v2;
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    return findMax(v.value4);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 309, column 7 - line 316, column 21): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 306, column 8 - line 316, column 21): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var filterWithKey = function (dictOrd) {
    return function (f) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Leaf.value;
            };
            if (v instanceof Node) {
                if (f(v.value2)(v.value3)) {
                    return unsafeBalancedNode(v.value2, v.value3, go(v.value4), go(v.value5));
                };
                if (Data_Boolean.otherwise) {
                    return unsafeJoinNodes(go(v.value4), go(v.value5));
                };
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 625, column 8 - line 631, column 47): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var filterKeys = function (dictOrd) {
    return function (f) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Leaf.value;
            };
            if (v instanceof Node) {
                if (f(v.value2)) {
                    return unsafeBalancedNode(v.value2, v.value3, go(v.value4), go(v.value5));
                };
                if (Data_Boolean.otherwise) {
                    return unsafeJoinNodes(go(v.value4), go(v.value5));
                };
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 638, column 8 - line 644, column 47): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var filter = function (dictOrd) {
    var $769 = filterWithKey(dictOrd);
    return function ($770) {
        return $769(Data_Function["const"]($770));
    };
};
var eqMap = function (dictEq) {
    var eqMapIter1 = eqMapIter(dictEq);
    return function (dictEq1) {
        var eq1 = Data_Eq.eq(eqMapIter1(dictEq1));
        return {
            eq: function (xs) {
                return function (ys) {
                    if (xs instanceof Leaf) {
                        if (ys instanceof Leaf) {
                            return true;
                        };
                        return false;
                    };
                    if (xs instanceof Node) {
                        if (ys instanceof Node && xs.value1 === ys.value1) {
                            return eq1(toMapIter(xs))(toMapIter(ys));
                        };
                        return false;
                    };
                    throw new Error("Failed pattern match at Data.Map.Internal (line 92, column 14 - line 103, column 16): " + [ xs.constructor.name ]);
                };
            }
        };
    };
};
var ordMap = function (dictOrd) {
    var ordMapIter1 = ordMapIter(dictOrd);
    var eqMap1 = eqMap(dictOrd.Eq0());
    return function (dictOrd1) {
        var compare = Data_Ord.compare(ordMapIter1(dictOrd1));
        var eqMap2 = eqMap1(dictOrd1.Eq0());
        return {
            compare: function (xs) {
                return function (ys) {
                    if (xs instanceof Leaf) {
                        if (ys instanceof Leaf) {
                            return Data_Ordering.EQ.value;
                        };
                        return Data_Ordering.LT.value;
                    };
                    if (ys instanceof Leaf) {
                        return Data_Ordering.GT.value;
                    };
                    return compare(toMapIter(xs))(toMapIter(ys));
                };
            },
            Eq0: function () {
                return eqMap2;
            }
        };
    };
};
var eq1Map = function (dictEq) {
    var eqMap1 = eqMap(dictEq);
    return {
        eq1: function (dictEq1) {
            return Data_Eq.eq(eqMap1(dictEq1));
        }
    };
};
var ord1Map = function (dictOrd) {
    var ordMap1 = ordMap(dictOrd);
    var eq1Map1 = eq1Map(dictOrd.Eq0());
    return {
        compare1: function (dictOrd1) {
            return Data_Ord.compare(ordMap1(dictOrd1));
        },
        Eq10: function () {
            return eq1Map1;
        }
    };
};
var empty = /* #__PURE__ */ (function () {
    return Leaf.value;
})();
var fromFoldable = function (dictOrd) {
    var insert1 = insert(dictOrd);
    return function (dictFoldable) {
        return Data_Foldable.foldl(dictFoldable)(function (m) {
            return function (v) {
                return insert1(v.value0)(v.value1)(m);
            };
        })(empty);
    };
};
var fromFoldableWith = function (dictOrd) {
    var insertWith1 = insertWith(dictOrd);
    return function (dictFoldable) {
        var foldl = Data_Foldable.foldl(dictFoldable);
        return function (f) {
            var f$prime = insertWith1(Data_Function.flip(f));
            return foldl(function (m) {
                return function (v) {
                    return f$prime(v.value0)(v.value1)(m);
                };
            })(empty);
        };
    };
};
var fromFoldableWithIndex = function (dictOrd) {
    var insert1 = insert(dictOrd);
    return function (dictFoldableWithIndex) {
        return Data_FoldableWithIndex.foldlWithIndex(dictFoldableWithIndex)(function (k) {
            return function (m) {
                return function (v) {
                    return insert1(k)(v)(m);
                };
            };
        })(empty);
    };
};
var monoidSemigroupMap = function () {
    return function (dictOrd) {
        var semigroupMap2 = semigroupMap1(dictOrd);
        return function (dictSemigroup) {
            var semigroupMap3 = semigroupMap2(dictSemigroup);
            return {
                mempty: empty,
                Semigroup0: function () {
                    return semigroupMap3;
                }
            };
        };
    };
};
var submap = function (dictOrd) {
    var foldSubmapBy1 = foldSubmapBy(dictOrd);
    var union1 = union(dictOrd);
    return function (kmin) {
        return function (kmax) {
            return foldSubmapBy1(union1)(empty)(kmin)(kmax)(singleton);
        };
    };
};
var unions = function (dictOrd) {
    var union1 = union(dictOrd);
    return function (dictFoldable) {
        return Data_Foldable.foldl(dictFoldable)(union1)(empty);
    };
};
var difference = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (m1) {
        return function (m2) {
            return unsafeDifference(compare, m1, m2);
        };
    };
};
var $$delete = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (k) {
        var go = function (v) {
            if (v instanceof Leaf) {
                return Leaf.value;
            };
            if (v instanceof Node) {
                var v1 = compare(k)(v.value2);
                if (v1 instanceof Data_Ordering.LT) {
                    return unsafeBalancedNode(v.value2, v.value3, go(v.value4), v.value5);
                };
                if (v1 instanceof Data_Ordering.GT) {
                    return unsafeBalancedNode(v.value2, v.value3, v.value4, go(v.value5));
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    return unsafeJoinNodes(v.value4, v.value5);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 496, column 7 - line 499, column 43): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 493, column 8 - line 499, column 43): " + [ v.constructor.name ]);
        };
        return go;
    };
};
var checkValid = function (dictOrd) {
    var greaterThan1 = Data_Ord.greaterThan(dictOrd);
    var lessThan1 = Data_Ord.lessThan(dictOrd);
    var go = function (v) {
        if (v instanceof Leaf) {
            return true;
        };
        if (v instanceof Node) {
            if (v.value4 instanceof Leaf) {
                if (v.value5 instanceof Leaf) {
                    return true;
                };
                if (v.value5 instanceof Node) {
                    return v.value0 === 2 && (v.value5.value0 === 1 && (v.value1 > v.value5.value1 && (greaterThan1(v.value5.value2)(v.value2) && go(v.value5))));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 262, column 11 - line 266, column 60): " + [ v.value5.constructor.name ]);
            };
            if (v.value4 instanceof Node) {
                if (v.value5 instanceof Leaf) {
                    return v.value0 === 2 && (v.value4.value0 === 1 && (v.value1 > v.value4.value1 && (lessThan1(v.value4.value2)(v.value2) && go(v.value4))));
                };
                if (v.value5 instanceof Node) {
                    return v.value0 > v.value5.value0 && (greaterThan1(v.value5.value2)(v.value2) && (v.value0 > v.value4.value0 && (lessThan1(v.value4.value2)(v.value2) && (abs(v.value5.value0 - v.value4.value0 | 0) < 2 && (((v.value5.value1 + v.value4.value1 | 0) + 1 | 0) === v.value1 && (go(v.value4) && go(v.value5)))))));
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 268, column 11 - line 272, column 108): " + [ v.value5.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Map.Internal (line 260, column 7 - line 272, column 108): " + [ v.value4.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Map.Internal (line 257, column 8 - line 272, column 108): " + [ v.constructor.name ]);
    };
    return go;
};
var catMaybes = function (dictOrd) {
    return mapMaybe(dictOrd)(identity);
};
var applyMap = function (dictOrd) {
    return {
        apply: intersectionWith(dictOrd)(identity),
        Functor0: function () {
            return functorMap;
        }
    };
};
var bindMap = function (dictOrd) {
    var mapMaybeWithKey1 = mapMaybeWithKey(dictOrd);
    var lookup1 = lookup(dictOrd);
    var applyMap1 = applyMap(dictOrd);
    return {
        bind: function (m) {
            return function (f) {
                return mapMaybeWithKey1(function (k) {
                    var $771 = lookup1(k);
                    return function ($772) {
                        return $771(f($772));
                    };
                })(m);
            };
        },
        Apply0: function () {
            return applyMap1;
        }
    };
};
var alter = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    return function (f) {
        return function (k) {
            return function (m) {
                var v = unsafeSplit(compare, k, m);
                var v2 = f(v.value0);
                if (v2 instanceof Data_Maybe.Nothing) {
                    return unsafeJoinNodes(v.value1, v.value2);
                };
                if (v2 instanceof Data_Maybe.Just) {
                    return unsafeBalancedNode(k, v2.value0, v.value1, v.value2);
                };
                throw new Error("Failed pattern match at Data.Map.Internal (line 512, column 3 - line 516, column 41): " + [ v2.constructor.name ]);
            };
        };
    };
};
var altMap = function (dictOrd) {
    return {
        alt: union(dictOrd),
        Functor0: function () {
            return functorMap;
        }
    };
};
var plusMap = function (dictOrd) {
    var altMap1 = altMap(dictOrd);
    return {
        empty: empty,
        Alt0: function () {
            return altMap1;
        }
    };
};
export {
    Leaf,
    Node,
    showTree,
    empty,
    isEmpty,
    singleton,
    checkValid,
    insert,
    insertWith,
    lookup,
    lookupLE,
    lookupLT,
    lookupGE,
    lookupGT,
    findMin,
    findMax,
    foldSubmap,
    submap,
    fromFoldable,
    fromFoldableWith,
    fromFoldableWithIndex,
    toUnfoldable,
    toUnfoldableUnordered,
    $$delete as delete,
    pop,
    member,
    alter,
    update,
    keys,
    values,
    union,
    unionWith,
    unions,
    intersection,
    intersectionWith,
    difference,
    isSubmap,
    size,
    filterWithKey,
    filterKeys,
    filter,
    mapMaybeWithKey,
    mapMaybe,
    catMaybes,
    IterDone,
    IterNext,
    toMapIter,
    stepAsc,
    stepAscCps,
    stepDesc,
    stepDescCps,
    stepUnordered,
    stepUnorderedCps,
    unsafeNode,
    unsafeBalancedNode,
    unsafeJoinNodes,
    unsafeSplit,
    Split,
    eq1Map,
    eqMap,
    ord1Map,
    ordMap,
    showMap,
    semigroupMap,
    monoidSemigroupMap,
    altMap,
    plusMap,
    functorMap,
    functorWithIndexMap,
    applyMap,
    bindMap,
    foldableMap,
    foldableWithIndexMap,
    traversableMap,
    traversableWithIndexMap,
    eqMapIter,
    ordMapIter
};
