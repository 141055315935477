import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_ST_Internal from "../Control.Monad.ST.Internal/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_ST from "../Data.Array.ST/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_FoldableWithIndex from "../Data.FoldableWithIndex/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map from "../Data.Map/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Set from "../Data.Set/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Random from "../Effect.Random/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var findWithIndex = /* #__PURE__ */ Data_FoldableWithIndex.findWithIndex(Data_Map_Internal.foldableWithIndexMap);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Data_Ord.ordInt);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var sequence_ = /* #__PURE__ */ Data_Foldable.sequence_(Control_Monad_ST_Internal.applicativeST)(Data_Foldable.foldableArray);
var fromFoldable = /* #__PURE__ */ Data_Map_Internal.fromFoldable(Data_Ord.ordInt)(Data_Foldable.foldableArray);
var fromFoldable1 = /* #__PURE__ */ Data_Array.fromFoldable(Data_Set.foldableSet);
var fromFoldable2 = /* #__PURE__ */ Data_List.fromFoldable(Data_Foldable.foldableArray);
var unfoldr = /* #__PURE__ */ Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var reverseLookup = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (v) {
        return function (m) {
            return map(function (v1) {
                return v1.index;
            })(findWithIndex(function (v1) {
                return function (v$prime) {
                    return eq(v)(v$prime);
                };
            })(m));
        };
    };
};
var reverseLookup1 = /* #__PURE__ */ reverseLookup(Data_Eq.eqInt);
var randomTupleRange = function (min) {
    return function (max) {
        return function __do() {
            var a = Effect_Random.randomInt(min)(max)();
            var b = Effect_Random.randomInt(min)(max)();
            return new Data_Tuple.Tuple(a, b);
        };
    };
};
var mapSwap = function (dictOrd) {
    var lookup1 = Data_Map_Internal.lookup(dictOrd);
    var insert = Data_Map_Internal.insert(dictOrd);
    return function (m) {
        return function (ka) {
            return function (kb) {
                return Data_Maybe.maybe(m)(identity)(bind1(lookup1(ka)(m))(function (va) {
                    return bind1(lookup1(kb)(m))(function (vb) {
                        var update1 = insert(ka)(vb)(m);
                        var update2 = insert(kb)(va)(update1);
                        return pure1(update2);
                    });
                }));
            };
        };
    };
};

// This function is SLOOOW, probably should be optimized.
var fixStep = function (dictOrd) {
    var lookup1 = Data_Map_Internal.lookup(dictOrd);
    var mapSwap1 = mapSwap(dictOrd);
    return function (dictShow) {
        return function (v) {
            return function (v1) {
                if (v1.value0 instanceof Data_List_Types.Nil) {
                    return Data_Maybe.Nothing.value;
                };
                if (v1.value0 instanceof Data_List_Types.Cons) {
                    return bind1(lookup(v1.value0.value0)(v))(function (v2) {
                        return bind1(lookup1(v2)(v1.value1))(function (haveIdx) {
                            return bind1(reverseLookup1(v1.value0.value0)(v1.value1))(function (swappedV) {
                                var updatedHave = mapSwap1(v1.value1)(v2)(swappedV);
                                return new Data_Maybe.Just(new Data_Tuple.Tuple(new Data_Tuple.Tuple(v2, swappedV), new Data_Tuple.Tuple(v1.value0.value1, updatedHave)));
                            });
                        });
                    });
                };
                throw new Error("Failed pattern match at Util.Shuffle (line 69, column 1 - line 69, column 152): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    };
};
var doSwap = function (ma) {
    return function (i) {
        return function (j) {
            return function __do() {
                var vim = Data_Array_ST.peek(i)(ma)();
                var vjm = Data_Array_ST.peek(j)(ma)();
                if (vim instanceof Data_Maybe.Just) {
                    if (vjm instanceof Data_Maybe.Just) {
                        Data_Array_ST.modify(i)(Data_Function["const"](vjm.value0))(ma)();
                        Data_Array_ST.modify(j)(Data_Function["const"](vim.value0))(ma)();
                        return Data_Unit.unit;
                    };
                    if (vjm instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    throw new Error("Failed pattern match at Util.Shuffle (line 28, column 18 - line 34, column 24): " + [ vjm.constructor.name ]);
                };
                if (vim instanceof Data_Maybe.Nothing) {
                    return Data_Unit.unit;
                };
                throw new Error("Failed pattern match at Util.Shuffle (line 27, column 3 - line 36, column 20): " + [ vim.constructor.name ]);
            };
        };
    };
};
var shuffle = function (orig) {
    var len = Data_Array.length(orig);
    return function __do() {
        var swaps = sequence(map1(function (i) {
            return function __do() {
                var j = Effect_Random.randomInt(i)(len - 1 | 0)();
                return new Data_Tuple.Tuple(i, j);
            };
        })(Data_Array.range(0)(len - 2 | 0)))();
        var a = Data_Array_ST.run(function __do() {
            var ma = Data_Array_ST.thaw(orig)();
            sequence_(map1(function (v) {
                return doSwap(ma)(v.value0)(v.value1);
            })(swaps))();
            return ma;
        });
        return a;
    };
};
var obtuseShuffle = function (dictOrd) {
    var fromFoldable3 = Data_Map_Internal.fromFoldable(dictOrd)(Data_Foldable.foldableArray);
    var mapSwap1 = mapSwap(dictOrd);
    var fixStep1 = fixStep(dictOrd);
    var notEq1 = Data_Eq.notEq(dictOrd.Eq0());
    return function (dictShow) {
        var fixStep2 = fixStep1(dictShow);
        return function (haveA) {
            return function (wantA) {
                return function (preshuffleCount) {
                    var have = fromFoldable3(Data_Array.zip(haveA)(Data_Array.range(0)(Data_Array.length(haveA) - 1 | 0)));
                    var want = fromFoldable(Data_Array.zip(Data_Array.range(0)(Data_Array.length(wantA) - 1 | 0))(wantA));
                    var maxIdx = Data_Map_Internal.size(have) - 1 | 0;
                    var keyArray = fromFoldable1(Data_Map.keys(have));
                    return function __do() {
                        var preshuffleIdxs = sequence(map1(Data_Function["const"](randomTupleRange(0)(maxIdx)))(Data_Array.range(1)(preshuffleCount)))();
                        var preshuffleMs = map1(function (v) {
                            return bind1(Data_Array.index(keyArray)(v.value0))(function (ki) {
                                return bind1(Data_Array.index(keyArray)(v.value1))(function (kj) {
                                    return pure1(new Data_Tuple.Tuple(ki, kj));
                                });
                            });
                        })(Data_Array.filter(function (v) {
                            return v.value0 !== v.value1;
                        })(preshuffleIdxs));
                        var preshuffles = Data_Array.catMaybes(preshuffleMs);
                        var haveAfterPreshuffle = Data_Array.foldl(function (m) {
                            return function (v) {
                                return mapSwap1(m)(v.value0)(v.value1);
                            };
                        })(have)(preshuffles);
                        var shuffledFix = shuffle(fromFoldable1(Data_Map.keys(want)))();
                        var fixOrder = fromFoldable2(shuffledFix);
                        var fixTuples = unfoldr(fixStep2(want))(new Data_Tuple.Tuple(fixOrder, haveAfterPreshuffle));
                        return Data_Array.filter(function (v) {
                            return notEq1(v.value0)(v.value1);
                        })(append(preshuffles)(fixTuples));
                    };
                };
            };
        };
    };
};
export {
    doSwap,
    shuffle,
    mapSwap,
    randomTupleRange,
    reverseLookup,
    fixStep,
    obtuseShuffle
};
