import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_NonEmpty from "../Data.List.NonEmpty/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
import * as Parsing_String_Basic from "../Parsing.String.Basic/index.js";
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var fromFoldable1 = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList);
var alt = /* #__PURE__ */ Control_Alt.alt(Parsing.altParserT);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var showRecordFieldsCons = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "x";
    }
});
var yIsSymbol = {
    reflectSymbol: function () {
        return "y";
    }
};
var genericShowConstructor1 = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ Data_Show.showRecordFieldsCons(yIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "z";
    }
})(Data_Show.showNumber))(Data_Show.showNumber))(Data_Show.showNumber))));
var normalIdxIsSymbol = {
    reflectSymbol: function () {
        return "normalIdx";
    }
};
var uvIdxIsSymbol = {
    reflectSymbol: function () {
        return "uvIdx";
    }
};
var vertexIdxIsSymbol = {
    reflectSymbol: function () {
        return "vertexIdx";
    }
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Eq.eqArray(/* #__PURE__ */ Data_Eq.eqRec()(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(/* #__PURE__ */ Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(vertexIdxIsSymbol)(Data_Eq.eqInt))()(uvIdxIsSymbol)(Data_Eq.eqInt))()(normalIdxIsSymbol)(Data_Eq.eqInt))));
var Comment = /* #__PURE__ */ (function () {
    function Comment() {

    };
    Comment.value = new Comment();
    return Comment;
})();
var Vertex = /* #__PURE__ */ (function () {
    function Vertex(value0) {
        this.value0 = value0;
    };
    Vertex.create = function (value0) {
        return new Vertex(value0);
    };
    return Vertex;
})();
var Normal = /* #__PURE__ */ (function () {
    function Normal(value0) {
        this.value0 = value0;
    };
    Normal.create = function (value0) {
        return new Normal(value0);
    };
    return Normal;
})();
var UV = /* #__PURE__ */ (function () {
    function UV(value0) {
        this.value0 = value0;
    };
    UV.create = function (value0) {
        return new UV(value0);
    };
    return UV;
})();
var Face = /* #__PURE__ */ (function () {
    function Face(value0) {
        this.value0 = value0;
    };
    Face.create = function (value0) {
        return new Face(value0);
    };
    return Face;
})();
var Unknown = /* #__PURE__ */ (function () {
    function Unknown() {

    };
    Unknown.value = new Unknown();
    return Unknown;
})();
var verticiesInObjLines = function (lines) {
    var step = function (v) {
        if (v instanceof Vertex) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    return fromFoldable(Data_List.catMaybes(map(step)(lines)));
};
var uvsInObjLines = function (lines) {
    var step = function (v) {
        if (v instanceof UV) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    return fromFoldable(Data_List.catMaybes(map(step)(lines)));
};
var parseVertex3 = /* #__PURE__ */ bind(Parsing_String_Basic.number)(function (x) {
    return bind(Parsing_Combinators.many1(Parsing_String_Basic.space))(function () {
        return bind(Parsing_String_Basic.number)(function (y) {
            return bind(Parsing_Combinators.many1(Parsing_String_Basic.space))(function () {
                return bind(Parsing_String_Basic.number)(function (z) {
                    return pure({
                        x: x,
                        y: y,
                        z: z
                    });
                });
            });
        });
    });
});
var parseVertex2 = /* #__PURE__ */ bind(Parsing_String_Basic.number)(function (x) {
    return bind(Parsing_Combinators.many1(Parsing_String_Basic.space))(function () {
        return bind(Parsing_String_Basic.number)(function (y) {
            return pure({
                x: x,
                y: y
            });
        });
    });
});
var parseVertex = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String["char"]("v"))(function () {
    return bind(Parsing_Combinators.many1(Parsing_String_Basic.space))(function () {
        return bind(parseVertex3)(function (v) {
            return bind(Parsing_String["char"]("\x0a"))(function () {
                return pure(new Vertex(v));
            });
        });
    });
});
var parseUV = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String.string("vt"))(function () {
    return bind(Parsing_Combinators.many1(Parsing_String_Basic.space))(function () {
        return bind(parseVertex2)(function (v) {
            return bind(Parsing_String["char"]("\x0a"))(function () {
                return pure(new UV(v));
            });
        });
    });
});
var parseOther = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_Combinators.manyTill_(Parsing_String.anyChar)(/* #__PURE__ */ Parsing_String["char"]("\x0a")))(function () {
    return pure(Unknown.value);
});
var parseNormal = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String.string("vn"))(function () {
    return bind(Parsing_Combinators.many1(Parsing_String_Basic.space))(function () {
        return bind(parseVertex3)(function (v) {
            return bind(Parsing_String["char"]("\x0a"))(function () {
                return pure(new Normal(v));
            });
        });
    });
});

// Assuming that only f z/z/z format is used. Other formats will fail.
var parseFaceVertex = /* #__PURE__ */ bind(Parsing_String_Basic.intDecimal)(function (vertexIdx) {
    return bind(Parsing_String["char"]("/"))(function () {
        return bind(Parsing_String_Basic.intDecimal)(function (uvIdx) {
            return bind(Parsing_String["char"]("/"))(function () {
                return bind(Parsing_String_Basic.intDecimal)(function (normalIdx) {
                    return pure({
                        vertexIdx: vertexIdx - 1 | 0,
                        uvIdx: uvIdx - 1 | 0,
                        normalIdx: normalIdx - 1 | 0
                    });
                });
            });
        });
    });
});
var parseFace = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String["char"]("f"))(function () {
    return bind(Parsing_Combinators.many1Till(applySecond(Parsing_Combinators.many1(Parsing_String_Basic.space))(parseFaceVertex))(Parsing_String["char"]("\x0a")))(function (fs) {
        return pure(new Face(fromFoldable1(fs)));
    });
});
var parseComment = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_String["char"]("#"))(function () {
    return bind(Parsing_Combinators.manyTill_(Parsing_String.anyChar)(Parsing_String["char"]("\x0a")))(function () {
        return pure(Comment.value);
    });
});
var parseObjLines = /* #__PURE__ */ bind(/* #__PURE__ */ Parsing_Combinators.many1(/* #__PURE__ */ alt(parseComment)(/* #__PURE__ */ alt(parseNormal)(/* #__PURE__ */ alt(parseUV)(/* #__PURE__ */ alt(parseVertex)(/* #__PURE__ */ alt(parseFace)(parseOther)))))))(function (lines) {
    return pure(Data_List_NonEmpty.toList(lines));
});
var normalsInObjLines = function (lines) {
    var step = function (v) {
        if (v instanceof Normal) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    return fromFoldable(Data_List.catMaybes(map(step)(lines)));
};
var genericObjLine = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Comment.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Vertex(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Normal(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new UV(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new Face(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return Unknown.value;
        };
        throw new Error("Failed pattern match at Util.ObjParser (line 46, column 1 - line 46, column 52): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Comment) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Vertex) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Normal) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof UV) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof Face) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof Unknown) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
        };
        throw new Error("Failed pattern match at Util.ObjParser (line 46, column 1 - line 46, column 52): " + [ x.constructor.name ]);
    }
};
var showObjLine = {
    show: /* #__PURE__ */ Data_Show_Generic.genericShow(genericObjLine)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Comment";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "Vertex";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor1({
        reflectSymbol: function () {
            return "Normal";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ showRecord(/* #__PURE__ */ showRecordFieldsCons(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil(yIsSymbol)(Data_Show.showNumber))(Data_Show.showNumber))))({
        reflectSymbol: function () {
            return "UV";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(/* #__PURE__ */ Data_Show_Generic.genericShowArgsArgument(/* #__PURE__ */ Data_Show.showArray(/* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons(normalIdxIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsCons(uvIdxIsSymbol)(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil(vertexIdxIsSymbol)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt)))))({
        reflectSymbol: function () {
            return "Face";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "Unknown";
        }
    })))))))
};
var facesInObjLines = function (lines) {
    var step = function (v) {
        if (v instanceof Face) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    return fromFoldable(Data_List.catMaybes(map(step)(lines)));
};
var parseObj = function (s) {
    var v = Parsing.runParser(s)(parseObjLines);
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Left(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Right({
            verticies: verticiesInObjLines(v.value0),
            normals: normalsInObjLines(v.value0),
            uvs: uvsInObjLines(v.value0),
            faces: facesInObjLines(v.value0)
        });
    };
    throw new Error("Failed pattern match at Util.ObjParser (line 165, column 14 - line 171, column 27): " + [ v.constructor.name ]);
};
var eqObjLine = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Comment && y instanceof Comment) {
                return true;
            };
            if (x instanceof Vertex && y instanceof Vertex) {
                return x.value0.x === y.value0.x && x.value0.y === y.value0.y && x.value0.z === y.value0.z;
            };
            if (x instanceof Normal && y instanceof Normal) {
                return x.value0.x === y.value0.x && x.value0.y === y.value0.y && x.value0.z === y.value0.z;
            };
            if (x instanceof UV && y instanceof UV) {
                return x.value0.x === y.value0.x && x.value0.y === y.value0.y;
            };
            if (x instanceof Face && y instanceof Face) {
                return eq1(x.value0)(y.value0);
            };
            if (x instanceof Unknown && y instanceof Unknown) {
                return true;
            };
            return false;
        };
    }
};
export {
    Comment,
    Vertex,
    Normal,
    UV,
    Face,
    Unknown,
    parseVertex3,
    parseVertex2,
    parseVertex,
    parseNormal,
    parseUV,
    parseFaceVertex,
    parseFace,
    parseComment,
    parseOther,
    parseObjLines,
    verticiesInObjLines,
    normalsInObjLines,
    uvsInObjLines,
    facesInObjLines,
    parseObj,
    eqObjLine,
    genericObjLine,
    showObjLine
};
