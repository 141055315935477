// Generated by purs version 0.15.10
import * as Animation_Animation from "../Animation.Animation/index.js";
import * as Assets from "../Assets/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Lens_Fold from "../Data.Lens.Fold/index.js";
import * as Data_Lens_Index from "../Data.Lens.Index/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map from "../Data.Map/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Maybe_First from "../Data.Maybe.First/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Profunctor_Choice from "../Data.Profunctor.Choice/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Set from "../Data.Set/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Random from "../Effect.Random/index.js";
import * as Graphics_Matrix from "../Graphics.Matrix/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Object_Present from "../Object.Present/index.js";
import * as Object_Text from "../Object.Text/index.js";
import * as Sound_Sound from "../Sound.Sound/index.js";
import * as Util_Grid from "../Util.Grid/index.js";
import * as Util_Shuffle from "../Util.Shuffle/index.js";
import * as Util_Wrap from "../Util.Wrap/index.js";
var choiceForget = /* #__PURE__ */ Data_Lens_Internal_Forget.choiceForget(Data_Maybe_First.monoidFirst);
var ix = /* #__PURE__ */ Data_Lens_Index.ix(/* #__PURE__ */ Data_Lens_Index.indexMap(Data_Ord.ordString));
var _position = /* #__PURE__ */ Object_Present["_position"](Data_Lens_Internal_Forget.strongForget);
var animateToF = /* #__PURE__ */ Animation_Animation.animateToF(Graphics_Matrix.completeRangeVec3);
var alter = /* #__PURE__ */ Data_Map_Internal.alter(Data_Ord.ordString);
var fromFoldable = /* #__PURE__ */ Data_List.fromFoldable(Data_Set.foldableSet);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var add = /* #__PURE__ */ Data_Semiring.add(Graphics_Matrix.semiringVec3);
var elemIndex = /* #__PURE__ */ Data_List.elemIndex(Data_Eq.eqString);
var animateAddF = /* #__PURE__ */ Animation_Animation.animateAddF(Graphics_Matrix.semiringVec3)(Graphics_Matrix.fromNumberVec3);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_List_Types.traversableList)(Effect.applicativeEffect);
var $$delete = /* #__PURE__ */ Data_Array["delete"](Data_Eq.eqString);
var obtuseShuffle = /* #__PURE__ */ Util_Shuffle.obtuseShuffle(Data_Ord.ordString)(Data_Show.showString);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var fromFoldable1 = /* #__PURE__ */ Data_List.fromFoldable(Data_Foldable.foldableArray);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_List_Types.semigroupList);
var animateToF1 = /* #__PURE__ */ Animation_Animation.animateToF(Graphics_WebGL.completeRangeWrappedColor);
var _wrap = /* #__PURE__ */ Util_Wrap["_wrap"]();
var sequence1 = /* #__PURE__ */ Data_Traversable.sequence(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var toUnfoldable = /* #__PURE__ */ Data_Set.toUnfoldable(Data_List_Types.unfoldableList);
var unrevealAnimation = function (pl) {
    var pl1 = pl(Data_Lens_Internal_Forget.strongForget)(choiceForget);
    return function (tl) {
        var tl1 = tl(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
        return function (name) {
            return Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.withState(function (s) {
                var pp = Data_Maybe.fromMaybe(Graphics_Matrix.vec3FromValues(0.0)(0.0)(0.0))(Data_Lens_Fold.preview((function () {
                    var $266 = ix(name)(Data_Lens_Internal_Forget.strongForget)(choiceForget);
                    return function ($267) {
                        return pl1($266(_position($267)));
                    };
                })())(s));
                return Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var tl2 = tl(dictStrong);
                    var _position1 = Object_Text["_position"](dictStrong);
                    return function (dictChoice) {
                        var $268 = tl2(dictChoice);
                        var $269 = ix(name)(dictStrong)(dictChoice);
                        return function ($270) {
                            return $268($269(_position1($270)));
                        };
                    };
                })(pp)));
            }), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(Animation_Animation.animateSetF(function (dictStrong) {
                var pl2 = pl(dictStrong);
                var _rotation = Object_Present["_rotation"](dictStrong);
                return function (dictChoice) {
                    var $271 = pl2(dictChoice);
                    var $272 = ix(name)(dictStrong)(dictChoice);
                    return function ($273) {
                        return $271($272(_rotation($273)));
                    };
                };
            })(function (t) {
                return Graphics_Matrix.quatRotateX(Graphics_Matrix.quatIdentity)(((1.0 - t) * 90.0 * Data_Number.pi) / 180.0);
            }))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(Animation_Animation.animateSetF(function (dictStrong) {
                var pl2 = pl(dictStrong);
                var _topRotationOffset = Object_Present["_topRotationOffset"](dictStrong);
                return function (dictChoice) {
                    var $274 = pl2(dictChoice);
                    var $275 = ix(name)(dictStrong)(dictChoice);
                    return function ($276) {
                        return $274($275(_topRotationOffset($276)));
                    };
                };
            })(function (t) {
                return Graphics_Matrix.quatRotateX(Graphics_Matrix.quatIdentity)(((1.0 - t) * -90.0 * Data_Number.pi) / 180.0);
            }))), Data_List_Types.Nil.value))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                var pl2 = pl(dictStrong);
                var _topPositionOffset = Object_Present["_topPositionOffset"](dictStrong);
                return function (dictChoice) {
                    var $277 = pl2(dictChoice);
                    var $278 = ix(name)(dictStrong)(dictChoice);
                    return function ($279) {
                        return $277($278(_topPositionOffset($279)));
                    };
                };
            })(Graphics_Matrix.vec3FromValues(0.0)(0.0)(0.0)))), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                return Data_Lens_Setter.over(tl1)(function (m) {
                    return alter(Data_Function["const"](Data_Maybe.Nothing.value))(name)(m);
                })(s);
            }), Data_List_Types.Nil.value)))));
        };
    };
};
var unrevealAllAnimation = function (nameSet) {
    return function (pl) {
        return function (tl) {
            var names = fromFoldable(nameSet);
            return Animation_Animation.parallel(map(unrevealAnimation(function (dictStrong) {
                var pl1 = pl(dictStrong);
                return function (dictChoice) {
                    return pl1(dictChoice);
                };
            })(function (dictStrong) {
                var tl1 = tl(dictStrong);
                return function (dictChoice) {
                    return tl1(dictChoice);
                };
            }))(names));
        };
    };
};
var swapAnimation = function (speed) {
    return function (p1l) {
        var p1l1 = p1l(Data_Lens_Internal_Forget.strongForget)(choiceForget);
        return function (p2l) {
            var p2l1 = p2l(Data_Lens_Internal_Forget.strongForget)(choiceForget);
            return Animation_Animation.withState(function (s) {
                var p2Pos = Data_Maybe.maybe(Graphics_Matrix.vec3FromValues(0.0)(0.0)(0.0))(identity)(Data_Lens_Fold.preview(function ($280) {
                    return p2l1(_position($280));
                })(s));
                var p1Pos = Data_Maybe.maybe(Graphics_Matrix.vec3FromValues(0.0)(0.0)(0.0))(identity)(Data_Lens_Fold.preview(function ($281) {
                    return p1l1(_position($281));
                })(s));
                return Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var p1l2 = p1l(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $282 = p1l2(dictChoice);
                        return function ($283) {
                            return $282(_position1($283));
                        };
                    };
                })(add(p1Pos)(Graphics_Matrix.vec3FromValues(0.0)(0.0)(2.0))))), new Data_List_Types.Cons(Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var p1l2 = p1l(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $284 = p1l2(dictChoice);
                        return function ($285) {
                            return $284(_position1($285));
                        };
                    };
                })(add(p2Pos)(Graphics_Matrix.vec3FromValues(0.0)(0.0)(2.0))))), new Data_List_Types.Cons(Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var p1l2 = p1l(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $286 = p1l2(dictChoice);
                        return function ($287) {
                            return $286(_position1($287));
                        };
                    };
                })(p2Pos))), Data_List_Types.Nil.value)))), new Data_List_Types.Cons(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var p2l2 = p2l(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $288 = p2l2(dictChoice);
                        return function ($289) {
                            return $288(_position1($289));
                        };
                    };
                })(add(p2Pos)(Graphics_Matrix.vec3FromValues(0.0)(0.0)(-2.0))))), new Data_List_Types.Cons(Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var p2l2 = p2l(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $290 = p2l2(dictChoice);
                        return function ($291) {
                            return $290(_position1($291));
                        };
                    };
                })(add(p1Pos)(Graphics_Matrix.vec3FromValues(0.0)(0.0)(-2.0))))), new Data_List_Types.Cons(Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var p2l2 = p2l(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $292 = p2l2(dictChoice);
                        return function ($293) {
                            return $292(_position1($293));
                        };
                    };
                })(p1Pos))), Data_List_Types.Nil.value)))), Data_List_Types.Nil.value)));
            });
        };
    };
};
var selectAnimation = function (speed) {
    return function (names) {
        return function (selection) {
            return function (ml) {
                var selIdx = Data_Maybe.fromMaybe(-1 | 0)(elemIndex(selection)(names));
                var positions$prime = Util_Grid.spread(Data_List.length(names));
                var positions = Data_Maybe.fromMaybe(positions$prime)(Data_List.modifyAt(selIdx)(add(Graphics_Matrix.vec3FromValues(0.0)(0.5)(0.0)))(positions$prime));
                var anims = map(function (v) {
                    return Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                        var ml1 = ml(dictStrong);
                        var _position1 = Object_Present["_position"](dictStrong);
                        return function (dictChoice) {
                            var $294 = ml1(dictChoice);
                            var $295 = ix(v.value0)(dictStrong)(dictChoice);
                            return function ($296) {
                                return $294($295(_position1($296)));
                            };
                        };
                    })(v.value1)));
                })(Data_List.zip(names)(positions));
                return Animation_Animation.parallel(anims);
            };
        };
    };
};
var revealAnimation = function (assets) {
    return function (ctx) {
        return function (pl) {
            var pl1 = pl(Data_Lens_Internal_Forget.strongForget)(choiceForget);
            return function (tl) {
                var tl1 = tl(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
                return function (name) {
                    return function __do() {
                        var textTex = Assets.getLabelTextTexture(ctx)(assets)(name)();
                        return Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                            return Data_Lens_Setter.over(tl1)(function (m) {
                                return alter(Data_Function["const"](new Data_Maybe.Just(new Object_Text.Text({
                                    position: Data_Maybe.fromMaybe(Graphics_Matrix.vec3FromValues(0.0)(0.0)(-3.0))(Data_Lens_Fold.preview((function () {
                                        var $297 = ix(name)(Data_Lens_Internal_Forget.strongForget)(choiceForget);
                                        return function ($298) {
                                            return pl1($297(_position($298)));
                                        };
                                    })())(s)),
                                    rotation: Graphics_Matrix.quatIdentity,
                                    texture: textTex
                                }))))(name)(m);
                            })(s);
                        }), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                            var pl2 = pl(dictStrong);
                            var _topPositionOffset = Object_Present["_topPositionOffset"](dictStrong);
                            return function (dictChoice) {
                                var $299 = pl2(dictChoice);
                                var $300 = ix(name)(dictStrong)(dictChoice);
                                return function ($301) {
                                    return $299($300(_topPositionOffset($301)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(0.5)(0.0)))), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(Animation_Animation.animateSetF(function (dictStrong) {
                            var pl2 = pl(dictStrong);
                            var _rotation = Object_Present["_rotation"](dictStrong);
                            return function (dictChoice) {
                                var $302 = pl2(dictChoice);
                                var $303 = ix(name)(dictStrong)(dictChoice);
                                return function ($304) {
                                    return $302($303(_rotation($304)));
                                };
                            };
                        })(function (t) {
                            return Graphics_Matrix.quatRotateX(Graphics_Matrix.quatIdentity)((t * 90.0 * Data_Number.pi) / 180.0);
                        }))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(Animation_Animation.animateSetF(function (dictStrong) {
                            var pl2 = pl(dictStrong);
                            var _topRotationOffset = Object_Present["_topRotationOffset"](dictStrong);
                            return function (dictChoice) {
                                var $305 = pl2(dictChoice);
                                var $306 = ix(name)(dictStrong)(dictChoice);
                                return function ($307) {
                                    return $305($306(_topRotationOffset($307)));
                                };
                            };
                        })(function (t) {
                            return Graphics_Matrix.quatRotateX(Graphics_Matrix.quatIdentity)((t * -90.0 * Data_Number.pi) / 180.0);
                        }))), Data_List_Types.Nil.value))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(animateAddF(function (dictStrong) {
                            var tl2 = tl(dictStrong);
                            var _position1 = Object_Text["_position"](dictStrong);
                            return function (dictChoice) {
                                var $308 = tl2(dictChoice);
                                var $309 = ix(name)(dictStrong)(dictChoice);
                                return function ($310) {
                                    return $308($309(_position1($310)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(0.0)(2.5)))), Data_List_Types.Nil.value)))));
                    };
                };
            };
        };
    };
};
var revealAllAnimation = function (assets) {
    return function (ctx) {
        return function (nameSet) {
            return function (pl) {
                return function (tl) {
                    var names = fromFoldable(nameSet);
                    return function __do() {
                        var anims = sequence(map(revealAnimation(assets)(ctx)(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            return function (dictChoice) {
                                return pl1(dictChoice);
                            };
                        })(function (dictStrong) {
                            var tl1 = tl(dictStrong);
                            return function (dictChoice) {
                                return tl1(dictChoice);
                            };
                        }))(names))();
                        return Animation_Animation.parallel(anims);
                    };
                };
            };
        };
    };
};
var removeAnimation = function (pl) {
    var pl1 = pl(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
    return function (tl) {
        var tl1 = tl(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
        return function (ol) {
            var ol1 = ol(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
            return function (name) {
                return Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(Animation_Animation.smoothCos(animateAddF(function (dictStrong) {
                    var tl2 = tl(dictStrong);
                    var _position1 = Object_Text["_position"](dictStrong);
                    return function (dictChoice) {
                        var $311 = tl2(dictChoice);
                        var $312 = ix(name)(dictStrong)(dictChoice);
                        return function ($313) {
                            return $311($312(_position1($313)));
                        };
                    };
                })(Graphics_Matrix.vec3FromValues(0.0)(-4.0)(0.0)))), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                    return Data_Lens_Setter.over(tl1)(function (m) {
                        return alter(Data_Function["const"](Data_Maybe.Nothing.value))(name)(m);
                    })(s);
                }), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                    return Data_Lens_Setter.over(pl1)(function (m) {
                        return alter(Data_Function["const"](Data_Maybe.Nothing.value))(name)(m);
                    })(s);
                }), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                    return Data_Lens_Setter.over(ol1)(function (a) {
                        return $$delete(name)(a);
                    })(s);
                }), Data_List_Types.Nil.value)))));
            };
        };
    };
};
var rearrangeAnimation = function (speed) {
    return function (names) {
        return function (ml) {
            var positions = Util_Grid.spread(Data_List.length(names));
            var anims = map(function (v) {
                return Animation_Animation.animate(speed)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                    var ml1 = ml(dictStrong);
                    var _position1 = Object_Present["_position"](dictStrong);
                    return function (dictChoice) {
                        var $314 = ml1(dictChoice);
                        var $315 = ix(v.value0)(dictStrong)(dictChoice);
                        return function ($316) {
                            return $314($315(_position1($316)));
                        };
                    };
                })(v.value1)));
            })(Data_List.zip(names)(positions));
            return Animation_Animation.parallel(anims);
        };
    };
};
var shuffleAnimation = function (ml) {
    return function (original) {
        return function (shuffled) {
            return function __do() {
                var shuffleTuples = obtuseShuffle(original)(shuffled)(50)();
                var swapSpeed = map1(function (i) {
                    return 5.0 + Data_Number.pow(Data_Int.toNumber(i) / Data_Int.toNumber(Data_Array.length(shuffleTuples)))(0.3) * 7.5;
                })(Data_Array.range(1)(Data_Array.length(shuffleTuples)));
                var anims = fromFoldable1(map1(function (v) {
                    return swapAnimation(v.value1)(function (dictStrong) {
                        var ml1 = ml(dictStrong);
                        return function (dictChoice) {
                            var $317 = ml1(dictChoice);
                            var $318 = ix(v.value0.value0)(dictStrong)(dictChoice);
                            return function ($319) {
                                return $317($318($319));
                            };
                        };
                    })(function (dictStrong) {
                        var ml1 = ml(dictStrong);
                        return function (dictChoice) {
                            var $320 = ml1(dictChoice);
                            var $321 = ix(v.value0.value1)(dictStrong)(dictChoice);
                            return function ($322) {
                                return $320($321($322));
                            };
                        };
                    });
                })(Data_Array.zip(shuffleTuples)(swapSpeed)));
                return Animation_Animation.sequence(append(new Data_List_Types.Cons(rearrangeAnimation(1.0 / 0.3)(fromFoldable1(original))(function (dictStrong) {
                    var ml1 = ml(dictStrong);
                    return function (dictChoice) {
                        return ml1(dictChoice);
                    };
                }), Data_List_Types.Nil.value))(append(anims)(new Data_List_Types.Cons(rearrangeAnimation(1.0 / 0.3)(fromFoldable1(shuffled))(function (dictStrong) {
                    var ml1 = ml(dictStrong);
                    return function (dictChoice) {
                        return ml1(dictChoice);
                    };
                }), Data_List_Types.Nil.value))));
            };
        };
    };
};
var openAnimation = function (assets) {
    return function (ctx) {
        return function (pl) {
            return function (tl) {
                var tl1 = tl(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
                return function (name) {
                    var focusPos = Graphics_Matrix.vec3FromValues(0.0)(0.0)(-6.0);
                    return function __do() {
                        var textTex = Assets.getLabelTextTexture(ctx)(assets)(name)();
                        return Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(animateToF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _position1 = Object_Present["_position"](dictStrong);
                            return function (dictChoice) {
                                var $323 = pl1(dictChoice);
                                var $324 = ix(name)(dictStrong)(dictChoice);
                                return function ($325) {
                                    return $323($324(_position1($325)));
                                };
                            };
                        })(focusPos))), new Data_List_Types.Cons(Animation_Animation.wait(1.0), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(Animation_Animation.shake(4)(animateAddF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _position1 = Object_Present["_position"](dictStrong);
                            return function (dictChoice) {
                                var $326 = pl1(dictChoice);
                                var $327 = ix(name)(dictStrong)(dictChoice);
                                return function ($328) {
                                    return $326($327(_position1($328)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(0.1)(0.0)))), new Data_List_Types.Cons(Animation_Animation.timedEffect(Sound_Sound.playSound(assets.shakeSound)), Data_List_Types.Nil.value))), new Data_List_Types.Cons(Animation_Animation.wait(0.5), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(Animation_Animation.shake(4)(animateAddF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _position1 = Object_Present["_position"](dictStrong);
                            return function (dictChoice) {
                                var $329 = pl1(dictChoice);
                                var $330 = ix(name)(dictStrong)(dictChoice);
                                return function ($331) {
                                    return $329($330(_position1($331)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.1)(0.0)(0.0)))), new Data_List_Types.Cons(Animation_Animation.timedEffect(Sound_Sound.playSound(assets.shakeSound)), Data_List_Types.Nil.value))), new Data_List_Types.Cons(Animation_Animation.wait(0.5), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 2.56)(Animation_Animation.smoothEaseOutQuint(animateAddF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _scale = Object_Present["_scale"](dictStrong);
                            return function (dictChoice) {
                                var $332 = pl1(dictChoice);
                                var $333 = ix(name)(dictStrong)(dictChoice);
                                return function ($334) {
                                    return $332($333(_scale($334)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.2)(0.1)(0.0)))), new Data_List_Types.Cons(Animation_Animation.timedEffect(Sound_Sound.playSound(assets.stretchSound)), Data_List_Types.Nil.value))), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.1)(animateAddF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _scale = Object_Present["_scale"](dictStrong);
                            return function (dictChoice) {
                                var $335 = pl1(dictChoice);
                                var $336 = ix(name)(dictStrong)(dictChoice);
                                return function ($337) {
                                    return $335($336(_scale($337)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(-0.2)(-0.1)(0.0))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(animateAddF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _topPositionOffset = Object_Present["_topPositionOffset"](dictStrong);
                            return function (dictChoice) {
                                var $338 = pl1(dictChoice);
                                var $339 = ix(name)(dictStrong)(dictChoice);
                                return function ($340) {
                                    return $338($339(_topPositionOffset($340)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(10.0)(0.0))), new Data_List_Types.Cons(Animation_Animation.timedEffect(Sound_Sound.playSound(assets.openSound)), Data_List_Types.Nil.value)))), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                            return Data_Lens_Setter.over(tl1)(function (m) {
                                return alter(Data_Function["const"](new Data_Maybe.Just(new Object_Text.Text({
                                    position: focusPos,
                                    rotation: Graphics_Matrix.quatIdentity,
                                    texture: textTex
                                }))))(name)(m);
                            })(s);
                        }), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.75)(animateAddF(function (dictStrong) {
                            var tl2 = tl(dictStrong);
                            var _position1 = Object_Text["_position"](dictStrong);
                            return function (dictChoice) {
                                var $341 = tl2(dictChoice);
                                var $342 = ix(name)(dictStrong)(dictChoice);
                                return function ($343) {
                                    return $341($342(_position1($343)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(2.0)(0.0))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.75)(Animation_Animation.animateSetF(function (dictStrong) {
                            var tl2 = tl(dictStrong);
                            var _rotation = Object_Text["_rotation"](dictStrong);
                            return function (dictChoice) {
                                var $344 = tl2(dictChoice);
                                var $345 = ix(name)(dictStrong)(dictChoice);
                                return function ($346) {
                                    return $344($345(_rotation($346)));
                                };
                            };
                        })(function (t) {
                            return Graphics_Matrix.quatRotateY(Graphics_Matrix.quatIdentity)((t * 360.0 * Data_Number.pi) / 180.0);
                        })), Data_List_Types.Nil.value))), new Data_List_Types.Cons(Animation_Animation.wait(0.5), new Data_List_Types.Cons(Animation_Animation.parallel(new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 0.5)(Animation_Animation.smoothCos(animateAddF(function (dictStrong) {
                            var pl1 = pl(dictStrong);
                            var _position1 = Object_Present["_position"](dictStrong);
                            return function (dictChoice) {
                                var $347 = pl1(dictChoice);
                                var $348 = ix(name)(dictStrong)(dictChoice);
                                return function ($349) {
                                    return $347($348(_position1($349)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(-4.0)(0.0)))), new Data_List_Types.Cons(Animation_Animation.animate(1.0 / 1.0)(Animation_Animation.smoothCos(animateAddF(function (dictStrong) {
                            var tl2 = tl(dictStrong);
                            var _position1 = Object_Text["_position"](dictStrong);
                            return function (dictChoice) {
                                var $350 = tl2(dictChoice);
                                var $351 = ix(name)(dictStrong)(dictChoice);
                                return function ($352) {
                                    return $350($351(_position1($352)));
                                };
                            };
                        })(Graphics_Matrix.vec3FromValues(0.0)(-2.0)(2.0)))), Data_List_Types.Nil.value))), Data_List_Types.Nil.value)))))))))))));
                    };
                };
            };
        };
    };
};
var colorAnimation = function (l) {
    return function (colorA) {
        return function (colorB) {
            return function (colorBow) {
                var a1_3 = Animation_Animation.animate(1.0 / 3.0)(animateToF1(function (dictStrong) {
                    var l1 = l(dictStrong);
                    var _colorBow = Object_Present["_colorBow"](dictStrong);
                    var _wrap1 = _wrap(dictStrong);
                    return function (dictChoice) {
                        var $353 = l1(dictChoice);
                        return function ($354) {
                            return $353(_colorBow(_wrap1($354)));
                        };
                    };
                })(colorBow));
                var a1_2 = Animation_Animation.animate(1.0 / 3.0)(animateToF1(function (dictStrong) {
                    var l1 = l(dictStrong);
                    var _colorB = Object_Present["_colorB"](dictStrong);
                    var _wrap1 = _wrap(dictStrong);
                    return function (dictChoice) {
                        var $355 = l1(dictChoice);
                        return function ($356) {
                            return $355(_colorB(_wrap1($356)));
                        };
                    };
                })(colorB));
                var a1_1 = Animation_Animation.animate(1.0 / 3.0)(animateToF1(function (dictStrong) {
                    var l1 = l(dictStrong);
                    var _colorA = Object_Present["_colorA"](dictStrong);
                    var _wrap1 = _wrap(dictStrong);
                    return function (dictChoice) {
                        var $357 = l1(dictChoice);
                        return function ($358) {
                            return $357(_colorA(_wrap1($358)));
                        };
                    };
                })(colorA));
                return Animation_Animation.parallel(new Data_List_Types.Cons(a1_1, new Data_List_Types.Cons(a1_2, new Data_List_Types.Cons(a1_3, Data_List_Types.Nil.value))));
            };
        };
    };
};
var randomizeColorAnimation = function (l) {
    return function __do() {
        var colorA$prime = sequence1([ Effect_Random.random, Effect_Random.random, Effect_Random.random ])();
        var colorA = {
            r: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorA$prime)(0)),
            b: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorA$prime)(1)),
            g: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorA$prime)(2)),
            a: 1.0
        };
        var colorB$prime = sequence1([ Effect_Random.random, Effect_Random.random, Effect_Random.random ])();
        var colorB = {
            r: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorB$prime)(0)),
            b: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorB$prime)(1)),
            g: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorB$prime)(2)),
            a: 1.0
        };
        var colorBow$prime = sequence1([ Effect_Random.random, Effect_Random.random, Effect_Random.random ])();
        var colorBow = {
            r: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorBow$prime)(0)),
            b: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorBow$prime)(1)),
            g: Data_Maybe.maybe(0.0)(identity)(Data_Array.index(colorBow$prime)(2)),
            a: 1.0
        };
        return colorAnimation(function (dictStrong) {
            var l1 = l(dictStrong);
            return function (dictChoice) {
                return l1(dictChoice);
            };
        })(colorA)(colorB)(colorBow);
    };
};
var randomizeAllColorAnimation = function (ps) {
    return function (ml) {
        var names = map(function (name) {
            return randomizeColorAnimation(function (dictStrong) {
                var ml1 = ml(dictStrong);
                return function (dictChoice) {
                    var $359 = ml1(dictChoice);
                    var $360 = ix(name)(dictStrong)(dictChoice);
                    return function ($361) {
                        return $359($360($361));
                    };
                };
            });
        })(toUnfoldable(Data_Map.keys(ps)));
        return function __do() {
            var anims = sequence(names)();
            return Animation_Animation.parallel(anims);
        };
    };
};
var setAllColorAnimation = function (ps) {
    return function (ml) {
        return function (colorA) {
            return function (colorB) {
                return function (colorBow) {
                    var anims = map(function (name) {
                        return colorAnimation(function (dictStrong) {
                            var ml1 = ml(dictStrong);
                            return function (dictChoice) {
                                var $362 = ml1(dictChoice);
                                var $363 = ix(name)(dictStrong)(dictChoice);
                                return function ($364) {
                                    return $362($363($364));
                                };
                            };
                        })(colorA)(colorB)(colorBow);
                    })(toUnfoldable(Data_Map.keys(ps)));
                    return Animation_Animation.parallel(anims);
                };
            };
        };
    };
};
export {
    rearrangeAnimation,
    selectAnimation,
    swapAnimation,
    shuffleAnimation,
    colorAnimation,
    randomizeColorAnimation,
    randomizeAllColorAnimation,
    setAllColorAnimation,
    openAnimation,
    removeAnimation,
    revealAnimation,
    unrevealAnimation,
    revealAllAnimation,
    unrevealAllAnimation
};
