import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Lens_Fold from "../Data.Lens.Fold/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Maybe_First from "../Data.Maybe.First/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Profunctor_Choice from "../Data.Profunctor.Choice/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showNumber);
var min = /* #__PURE__ */ Data_Ord.min(Data_Ord.ordNumber);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var sequence_ = /* #__PURE__ */ Data_Foldable.sequence_(Effect.applicativeEffect)(Data_List_Types.foldableList);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var minimum = /* #__PURE__ */ Data_Foldable.minimum(Data_Ord.ordNumber)(Data_List_Types.foldableList);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var all = /* #__PURE__ */ Data_Foldable.all(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean);
var choiceForget = /* #__PURE__ */ Data_Lens_Internal_Forget.choiceForget(Data_Maybe_First.monoidFirst);
var RunningAnimation = /* #__PURE__ */ (function () {
    function RunningAnimation(value0) {
        this.value0 = value0;
    };
    RunningAnimation.create = function (value0) {
        return new RunningAnimation(value0);
    };
    return RunningAnimation;
})();
var PendingAnimation = /* #__PURE__ */ (function () {
    function PendingAnimation(value0) {
        this.value0 = value0;
    };
    PendingAnimation.create = function (value0) {
        return new PendingAnimation(value0);
    };
    return PendingAnimation;
})();
var ParallelAnimation = /* #__PURE__ */ (function () {
    function ParallelAnimation(value0) {
        this.value0 = value0;
    };
    ParallelAnimation.create = function (value0) {
        return new ParallelAnimation(value0);
    };
    return ParallelAnimation;
})();
var EffectAnimation = /* #__PURE__ */ (function () {
    function EffectAnimation(value0) {
        this.value0 = value0;
    };
    EffectAnimation.create = function (value0) {
        return new EffectAnimation(value0);
    };
    return EffectAnimation;
})();
var SequenceAnimation = /* #__PURE__ */ (function () {
    function SequenceAnimation(value0) {
        this.value0 = value0;
    };
    SequenceAnimation.create = function (value0) {
        return new SequenceAnimation(value0);
    };
    return SequenceAnimation;
})();
var NilAnimation = /* #__PURE__ */ (function () {
    function NilAnimation() {

    };
    NilAnimation.value = new NilAnimation();
    return NilAnimation;
})();
var StatefulAnimation = /* #__PURE__ */ (function () {
    function StatefulAnimation(value0) {
        this.value0 = value0;
    };
    StatefulAnimation.create = function (value0) {
        return new StatefulAnimation(value0);
    };
    return StatefulAnimation;
})();
var StepResult = /* #__PURE__ */ (function () {
    function StepResult(value0) {
        this.value0 = value0;
    };
    StepResult.create = function (value0) {
        return new StepResult(value0);
    };
    return StepResult;
})();
var showAnimation = {
    show: function (v) {
        if (v instanceof RunningAnimation) {
            return "RunnngAnimation{x" + (show(v.value0.speed) + (" t: " + (show(v.value0.t) + "}")));
        };
        if (v instanceof PendingAnimation) {
            return "PendingAnimation{x" + (show(v.value0.speed) + "}");
        };
        if (v instanceof ParallelAnimation) {
            return "ParallelAnimation{" + (Data_Show.show(Data_List_Types.showList(showAnimation))(v.value0.animations) + "}");
        };
        if (v instanceof EffectAnimation) {
            return "EffectAnimation{}";
        };
        if (v instanceof SequenceAnimation) {
            return "SequenceAnimation{" + (Data_Show.show(Data_List_Types.showList(showAnimation))(v.value0.animations) + "}");
        };
        if (v instanceof NilAnimation) {
            return "NilAnimation{}";
        };
        if (v instanceof StatefulAnimation) {
            return "StatefulAnimation{}";
        };
        throw new Error("Failed pattern match at Animation.Animation (line 204, column 1 - line 211, column 53): " + [ v.constructor.name ]);
    }
};
var show1 = /* #__PURE__ */ Data_Show.show(showAnimation);
var showStepResult = function (dictShow) {
    var show2 = Data_Show.show(dictShow);
    return {
        show: function (v) {
            return "StepResult { animation : " + (show1(v.value0.animation) + (" state : " + (show2(v.value0.state) + (" remainingDelta: " + (show(v.value0.remainingDelta) + "}")))));
        }
    };
};
var fromNumberNumber = {
    fromNumber: function (v) {
        return v;
    }
};
var completeRangeNumber = {
    percentInRange: function (from) {
        return function (to) {
            return function (t) {
                return (to - from) * t + from;
            };
        };
    }
};
var withState = function (mkAnim) {
    return new StatefulAnimation({
        mkAnim: mkAnim
    });
};
var wait = function (time) {
    var mkF = function (v) {
        return function (state) {
            return function (v1) {
                return state;
            };
        };
    };
    return new PendingAnimation({
        mkF: mkF,
        speed: 1.0 / time
    });
};
var timedEffect = function (e) {
    return new EffectAnimation({
        effect: e
    });
};
var step = function (v) {
    return function (v1) {
        return function (v2) {
            if (v instanceof RunningAnimation) {
                var scaledDT = v2 * v.value0.speed;
                var newT = min(v.value0.t + scaledDT)(1.0);
                var unusedDT = (function () {
                    var $80 = newT >= 1.0;
                    if ($80) {
                        return v2 - (newT - v.value0.t) / v.value0.speed;
                    };
                    return 0.0;
                })();
                var newState = v.value0.f(v1)(newT);
                return new StepResult({
                    animation: new RunningAnimation({
                        f: v.value0.f,
                        speed: v.value0.speed,
                        t: newT
                    }),
                    state: newState,
                    effect: pure(Data_Unit.unit),
                    remainingDelta: unusedDT
                });
            };
            if (v instanceof PendingAnimation) {
                if (v2 <= 0.0) {
                    return new StepResult({
                        animation: v,
                        state: v1,
                        effect: pure(Data_Unit.unit),
                        remainingDelta: v2
                    });
                };
                if (Data_Boolean.otherwise) {
                    var ra = new RunningAnimation({
                        f: v.value0.mkF(v1),
                        speed: v.value0.speed,
                        t: 0.0
                    });
                    return step(ra)(v1)(v2);
                };
            };
            if (v instanceof EffectAnimation) {
                return new StepResult({
                    animation: NilAnimation.value,
                    state: v1,
                    effect: v.value0.effect,
                    remainingDelta: v2
                });
            };
            if (v instanceof ParallelAnimation) {
                var stepped = function (v3) {
                    return function (v4) {
                        if (v4 instanceof Data_List_Types.Nil) {
                            return Data_List_Types.Nil.value;
                        };
                        if (v4 instanceof Data_List_Types.Cons) {
                            var nextResult = step(v4.value0)(v3.value0.state)(v2);
                            return new Data_List_Types.Cons(nextResult, stepped(nextResult)(v4.value1));
                        };
                        throw new Error("Failed pattern match at Animation.Animation (line 94, column 5 - line 94, column 73): " + [ v3.constructor.name, v4.constructor.name ]);
                    };
                };
                var emptyResult = new StepResult({
                    animation: NilAnimation.value,
                    state: v1,
                    effect: pure(Data_Unit.unit),
                    remainingDelta: v2
                });
                var results = stepped(emptyResult)(v.value0.animations);
                var newAnims = map(function (v3) {
                    return v3.value0.animation;
                })(results);
                var newEffect = sequence_(map(function (v3) {
                    return v3.value0.effect;
                })(results));
                var newRemainingDelta = Data_Maybe.maybe(v2)(identity)(minimum(map(function (v3) {
                    return v3.value0.remainingDelta;
                })(results)));
                var newState = Data_Maybe.maybe(v1)(identity)(map1(function (v3) {
                    return v3.value0.state;
                })(Data_List.last(results)));
                return new StepResult({
                    animation: new ParallelAnimation({
                        animations: newAnims
                    }),
                    state: newState,
                    effect: newEffect,
                    remainingDelta: newRemainingDelta
                });
            };
            if (v instanceof SequenceAnimation && v2 <= 0.0) {
                return new StepResult({
                    animation: v,
                    state: v1,
                    effect: pure(Data_Unit.unit),
                    remainingDelta: v2
                });
            };
            var v3 = function (v4) {
                if (v instanceof SequenceAnimation && Data_Boolean.otherwise) {
                    return new StepResult({
                        animation: v,
                        state: v1,
                        effect: pure(Data_Unit.unit),
                        remainingDelta: v2
                    });
                };
                if (v instanceof NilAnimation) {
                    return new StepResult({
                        animation: NilAnimation.value,
                        state: v1,
                        effect: pure(Data_Unit.unit),
                        remainingDelta: v2
                    });
                };
                if (v instanceof StatefulAnimation) {
                    if (v2 <= 0.0) {
                        return new StepResult({
                            animation: v,
                            state: v1,
                            effect: pure(Data_Unit.unit),
                            remainingDelta: v2
                        });
                    };
                    if (Data_Boolean.otherwise) {
                        return step(v.value0.mkAnim(v1))(v1)(v2);
                    };
                };
                throw new Error("Failed pattern match at Animation.Animation (line 56, column 1 - line 56, column 61): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
            if (v instanceof SequenceAnimation) {
                var $107 = Data_List.head(v.value0.animations);
                if ($107 instanceof Data_Maybe.Just) {
                    var $108 = Data_List.tail(v.value0.animations);
                    if ($108 instanceof Data_Maybe.Just) {
                        var v4 = step($107.value0)(v1)(v2);
                        var $110 = v4.value0.remainingDelta > 0.0;
                        if ($110) {
                            return step(new SequenceAnimation({
                                animations: $108.value0
                            }))(v4.value0.state)(v4.value0.remainingDelta);
                        };
                        return new StepResult({
                            animation: new SequenceAnimation({
                                animations: new Data_List_Types.Cons(v4.value0.animation, $108.value0)
                            }),
                            state: v4.value0.state,
                            effect: v4.value0.effect,
                            remainingDelta: v4.value0.remainingDelta
                        });
                    };
                    return v3(true);
                };
                return v3(true);
            };
            return v3(true);
        };
    };
};
var smoothEaseOutQuint = function (f) {
    return function (init) {
        return function (cur) {
            return function (t) {
                return f(init)(cur)(1.0 - Data_Number.pow(1.0 - t)(5.0));
            };
        };
    };
};
var smoothCos = function (f) {
    return function (init) {
        return function (cur) {
            return function (t) {
                return f(init)(cur)((1.0 - Data_Number.cos(Data_Number.pi * t)) / 2.0);
            };
        };
    };
};

// https://www.desmos.com/calculator/rkh1o7vt6h
var shake = function (k) {
    return function (f) {
        return function (init) {
            return function (cur) {
                return function (t) {
                    var k$prime = Data_Int.toNumber(k);
                    var bm = 2.0 * k$prime * (t - 1.0 / (4.0 * k$prime));
                    
                    // Do double module to ensure positive value
var a = Data_Number.remainder(Data_Number.remainder(bm)(2.0) + 2.0)(2.0) - 1.0;
                    var t$prime = 2.0 * (Data_Number.sign(a) * a - 0.5);
                    return f(init)(cur)(t$prime);
                };
            };
        };
    };
};
var sequence = function (as) {
    return new SequenceAnimation({
        animations: as
    });
};
var percentInRange = function (dict) {
    return dict.percentInRange;
};
var parallel = function (as) {
    return new ParallelAnimation({
        animations: as
    });
};
var fromNumber = function (dict) {
    return dict.fromNumber;
};
var finished = function (v) {
    if (v instanceof RunningAnimation) {
        return v.value0.t >= 1.0;
    };
    if (v instanceof PendingAnimation) {
        return false;
    };
    if (v instanceof ParallelAnimation) {
        return all(finished)(v.value0.animations);
    };
    if (v instanceof SequenceAnimation) {
        return Data_List["null"](v.value0.animations);
    };
    if (v instanceof EffectAnimation) {
        return false;
    };
    if (v instanceof StatefulAnimation) {
        return false;
    };
    if (v instanceof NilAnimation) {
        return true;
    };
    throw new Error("Failed pattern match at Animation.Animation (line 139, column 1 - line 139, column 45): " + [ v.constructor.name ]);
};
var animateToF = function (dictCompleteRange) {
    var percentInRange1 = percentInRange(dictCompleteRange);
    return function (l) {
        var l1 = l(Data_Lens_Internal_Forget.strongForget)(choiceForget);
        var l2 = l(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
        return function (to) {
            return function (initState) {
                var fromM = Data_Lens_Fold.preview(l1)(initState);
                return function (state) {
                    return function (t) {
                        if (fromM instanceof Data_Maybe.Just) {
                            return Data_Lens_Setter.set(l2)(percentInRange1(fromM.value0)(to)(t))(state);
                        };
                        if (fromM instanceof Data_Maybe.Nothing) {
                            return state;
                        };
                        throw new Error("Failed pattern match at Animation.Animation (line 174, column 21 - line 176, column 25): " + [ fromM.constructor.name ]);
                    };
                };
            };
        };
    };
};
var animateSetF = function (l) {
    var l1 = l(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
    return function (newA) {
        return function (v) {
            return function (curState) {
                return function (t) {
                    return Data_Lens_Setter.set(l1)(newA(t))(curState);
                };
            };
        };
    };
};
var animateAddF = function (dictSemiring) {
    var add1 = Data_Semiring.add(dictSemiring);
    var mul1 = Data_Semiring.mul(dictSemiring);
    return function (dictFromNumber) {
        var fromNumber1 = fromNumber(dictFromNumber);
        return function (l) {
            var l1 = l(Data_Lens_Internal_Forget.strongForget)(choiceForget);
            var l2 = l(Data_Profunctor_Strong.strongFn)(Data_Profunctor_Choice.choiceFn);
            return function (offset) {
                return function (initState) {
                    var fromM = Data_Lens_Fold.preview(l1)(initState);
                    return function (state) {
                        return function (t) {
                            if (fromM instanceof Data_Maybe.Just) {
                                return Data_Lens_Setter.set(l2)(add1(fromM.value0)(mul1(offset)(fromNumber1(t))))(state);
                            };
                            if (fromM instanceof Data_Maybe.Nothing) {
                                return state;
                            };
                            throw new Error("Failed pattern match at Animation.Animation (line 181, column 21 - line 183, column 25): " + [ fromM.constructor.name ]);
                        };
                    };
                };
            };
        };
    };
};
var animate = function (speed) {
    return function (mkF) {
        return new PendingAnimation({
            mkF: mkF,
            speed: speed
        });
    };
};
var instant = function (f) {
    return animate(1.0 / 0.0)(function (init) {
        return function (v) {
            return function (v1) {
                return f(init);
            };
        };
    });
};
export {
    fromNumber,
    percentInRange,
    RunningAnimation,
    PendingAnimation,
    ParallelAnimation,
    EffectAnimation,
    SequenceAnimation,
    NilAnimation,
    StatefulAnimation,
    StepResult,
    step,
    finished,
    animate,
    parallel,
    sequence,
    instant,
    timedEffect,
    wait,
    withState,
    animateToF,
    animateAddF,
    animateSetF,
    smoothCos,
    smoothEaseOutQuint,
    shake,
    completeRangeNumber,
    fromNumberNumber,
    showStepResult,
    showAnimation
};
