const vars = [];

export function createVar(v) {
  return () => {
    const idx = vars.length;
    vars.push(v);
    return idx;
  };
}
export function readVar(idx) {
  return () => {
    return vars[idx];
  };
}
export function setVar(idx) {
  return function(v) {
    return () => {
      vars[idx] = v;
    };
  };
}