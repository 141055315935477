import * as $foreign from "./foreign.js";
import * as Control_Promise from "../Control.Promise/index.js";
var SourceFile = /* #__PURE__ */ (function () {
    function SourceFile(value0) {
        this.value0 = value0;
    };
    SourceFile.create = function (value0) {
        return new SourceFile(value0);
    };
    return SourceFile;
})();
var loadTextFile = function (v) {
    return Control_Promise.toAffE($foreign.loadTextFile_(v.value0));
};
export {
    SourceFile,
    loadTextFile
};
