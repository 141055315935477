// Generated by purs version 0.15.10
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Semigroupoid from "../Control.Semigroupoid/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Profunctor from "../Data.Profunctor/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var right = function (dict) {
    return dict.right;
};
var left = function (dict) {
    return dict.left;
};
var splitChoice = function (dictCategory) {
    var composeFlipped = Control_Semigroupoid.composeFlipped(dictCategory.Semigroupoid0());
    return function (dictChoice) {
        var left1 = left(dictChoice);
        var right1 = right(dictChoice);
        return function (l) {
            return function (r) {
                return composeFlipped(left1(l))(right1(r));
            };
        };
    };
};
var fanin = function (dictCategory) {
    var identity1 = Control_Category.identity(dictCategory);
    var composeFlipped = Control_Semigroupoid.composeFlipped(dictCategory.Semigroupoid0());
    var splitChoice1 = splitChoice(dictCategory);
    return function (dictChoice) {
        var dimap = Data_Profunctor.dimap(dictChoice.Profunctor0());
        var splitChoice2 = splitChoice1(dictChoice);
        return function (l) {
            return function (r) {
                var join = dimap(Data_Either.either(identity)(identity))(identity)(identity1);
                return composeFlipped(splitChoice2(l)(r))(join);
            };
        };
    };
};
var choiceFn = {
    left: function (v) {
        return function (v1) {
            if (v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(v(v1.value0));
            };
            if (v1 instanceof Data_Either.Right) {
                return new Data_Either.Right(v1.value0);
            };
            throw new Error("Failed pattern match at Data.Profunctor.Choice (line 32, column 1 - line 35, column 16): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    right: /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither),
    Profunctor0: function () {
        return Data_Profunctor.profunctorFn;
    }
};
export {
    left,
    right,
    splitChoice,
    fanin,
    choiceFn
};
