export function float32ArrayCreate(arr) {
  return new Float32Array(arr)
}
export function float32ArrayConcat(arrs) {
  let totalLength = 0;
  for (let arr of arrs) {
    totalLength += arr.length;
  }

  const result = new Float32Array(totalLength);
  let offset = 0;
  for (let arr of arrs) {
    result.set(arr, offset);
    offset += arr.length;
  }

  return result;
}
export function showFloat32Array_(arr) {
  return arr.toString();
}
export function colorToArray(color) {
  return new Float32Array([color.r, color.g, color.b, color.a]);
}
export function getWebGLContext_(just) {
  return function (nothing) {
    return function (id) {
      return function() {
        const canvas = document.querySelector("#" + id);
        if (canvas === null) {
          return nothing
        }

        const gl = canvas.getContext("webgl2");
        if (gl ===  null) {
          return nothing;
        }

        return just(gl);
      };
    };
  };
}

export function clearColor(gl) {
  return function(color) {
    return () => { gl.clearColor(color.r, color.g, color.b, color.a); };
  };
}

export function clear(gl) {
  return () => { gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT); };
}

export function enableDepthBuffer(gl) {
  return () => { 
    gl.enable(gl.DEPTH_TEST);
    gl.depthFunc(gl.LEQUAL);
  }
}
export function blendFuncOne(gl) { return gl.ONE; }
export function blendFuncSrcAlpha(gl) { return gl.SRC_ALPHA; }
export function blendFuncOneMinusSrcAlpha(gl) { return gl.ONE_MINUS_SRC_ALPHA; } 
export function enableAlphaBlend(gl) {
  return function (srcFunc) {
    return function (destFunc) {
      return () => {
        gl.enable(gl.BLEND);
        gl.blendFunc(srcFunc, destFunc);
      };
    };
  };
}
export function disableAlphaBlend(gl) {
  return () => {
    gl.disable(gl.BLEND);
  };
}
export function depthMask(gl) {
  return function(v) {
    return () => {
      gl.depthMask(v);
    };
  };
}

export function webGLVertexShader(gl) { return gl.VERTEX_SHADER; }
export function webGLFragmentShader(gl) { return gl.FRAGMENT_SHADER; }
export function loadShader_(left) {
  return function (right) {
    return function(gl) {
      return function(type) {
        return function(source) {
          return () => {
            const shader = gl.createShader(type)
            if (shader === null) {
              return left("Could not create shader");
            }
            gl.shaderSource(shader, source);
            gl.compileShader(shader);
            if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
              const err = `An error occured compiling the shaders: ${gl.getShaderInfoLog(shader)}`;
              gl.deleteShader(shader);
              return left(err);
            }

            return right(shader);
          };
        };
      };
    };
  };
}

export function deleteShader(gl) {
  return function(shader) {
    return () => { gl.deleteShader(shader); };
  };
}

export function loadProgram_(left) {
  return function(right) {
    return function(gl) {
      return function(vertexShader) {
        return function(fragmentShader) {
          return () => {
            const program = gl.createProgram()
            gl.attachShader(program, vertexShader);
            gl.attachShader(program, fragmentShader);
            gl.linkProgram(program);

            if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
              const err = `An error occured linking shader program: ${gl.getProgramInfoLog(program)}`;
              gl.deleteProgram(program);
              return left(err);
            }

            return right(program);
          };
        };
      };
    };
  };
}

export function deleteProgram(gl) {
  return function(program) {
    return () => {
      gl.deleteProgram(program);
    };
  };
}

export function useProgram(gl) {
  return function(program) {
    return () => {
      gl.useProgram(program);
    };
  };
}

export function webGLArrayBufferTarget(gl) { return gl.ARRAY_BUFFER; }
export function webGLElementArrayBufferTarget(gl) { return gl.ELEMENT_ARRAY_BUFFER; }
export function webGLCopyReadBufferTarget(gl) { return gl.COPY_READ_BUFFER; }
export function webGLCopyWriteBufferTarget(gl) { return gl.COPY_WRITE_BUFFER; }
export function webGLTransformFeedbackBufferTarget(gl) { return gl.TRANSFORM_FEEDBACK_BUFFER; }
export function webGLUniformBufferTarget(gl) { return gl.UNIFORM_BUFFER; }
export function webGLPixelPackBufferTarget(gl) { return gl.PIXEL_PACK_BUFFER_TARGET; }
export function webGLPixelUnpackBufferTarget(gl) { return gl.PIXEL_UNPACK_BUFFER_TARGET; }

export function webGLStaticDraw(gl) { return gl.STATIC_DRAW; }
export function webGLDynamicDraw(gl) { return gl.DYNAMIC_DRAW; }

export function createBuffer(gl) {
  return () => {
    return gl.createBuffer();
  };
}
export function deleteBuffer(gl) {
  return function(buffer) {
    return () => {
      return gl.deleteBuffer(buffer);
    };
  };
}
export function bindBuffer(gl) {
  return function(buffer) {
    return function(target) {
      return () => {
        gl.bindBuffer(target, buffer);
      };
    };
  };
}
export function floatBufferData(gl) {
  return function(target) {
    return function(usage) {
      return function(data) {
        return () => {
          gl.bufferData(target, data, usage);
        };
      };
    };
  };
}
export function getAttributeLocation(gl) {
  return function(program) {
    return function(name) {
      return () => {
        return gl.getAttribLocation(program, name)
      };
    };
  };
}
export function getUniformLocation(gl) {
  return function(program) {
    return function(name) {
      return () => {
        return gl.getUniformLocation(program, name);
      };
    };
  };
}
export function vertexAttributePointer(gl) {
  return function(index) {
    return function(opts) {
      return () => {
        if (index === -1) { return; }
        gl.enableVertexAttribArray(index);
        gl.vertexAttribPointer(index, opts.size, gl.FLOAT, opts.normalized, opts.stride, opts.offset);
      };
    };
  };
}
export function vertexAttributeDivisor(gl) {
  return function(index) {
    return function(divisor) {
      return () => {
        if (index === -1) { return; }
        gl.vertexAttribDivisor(index, divisor);
      };
    };
  };
}
export function uniformMatrix4fv(gl) {
  return function(index) {
    return function(matrix) {
      return () => {
        if (index === -1) { return; }
        gl.uniformMatrix4fv(index, false, matrix);
      };
    };
  };
}

export function drawArrays(gl) {
  return function(offset) {
    return function(size) {
      return () => {
        gl.drawArrays(gl.TRIANGLES, offset, size);
      };
    }
  }
}
export function drawArraysInstanced(gl) {
  return function(offset) {
    return function(count) {
      return function(instanceCount) {
        return () => {
          gl.drawArraysInstanced(gl.TRIANGLES, offset, count, instanceCount);
        };
      }
    }
  }
}

let _lastAnimationFrame = 0;
export function requestAnimationFrame(callback) {
  return () => {
    window.requestAnimationFrame((n) => {
      if (_lastAnimationFrame == 0) {
        callback(0)();
      } else {
        callback((n-_lastAnimationFrame)/1000)();
      }
      _lastAnimationFrame = n;
    });
  };
}

export function webGLOffsetLocation(attrPtr) {
  return function(offset) {
    if (attrPtr === -1) { return attrPtr; }
    return attrPtr + offset;
  };
}

export function createTexture(gl) {
  return () => {
    return gl.createTexture();
  };
}
export function bindTexture(gl) {
  return function(tex) {
    return () => {
      gl.bindTexture(gl.TEXTURE_2D, tex);
    };
  };
}
export function texImage2D(gl) {
  return function(imgData) {
    return () => {
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 512, 512, 0, gl.RGBA, gl.UNSIGNED_BYTE, imgData);
    };
  };
}
export function generateMipmap(gl) {
  return () => {
    gl.generateMipmap(gl.TEXTURE_2D);
  };
}
export function pixelStoreiUnpackFlipY(gl) {
  return function(v) {
    return () => {
      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, v);
    }
  };
}
export function pixelStoreiUnpackPremultiplyAlpha(gl) {
  return function(v) {
    return () => {
      gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, v);
    }
  };
}
export function activeTexture0(gl) {
  return () => {
    gl.activeTexture(gl.TEXTURE0);
  };
}
export function uniform1i(gl) {
  return function(index) {
    return function (texUnit) {
      return () => {
        if (index === -1) { return; }
        gl.uniform1i(index, texUnit);
      };
    };
  };
}

//foreign import createVertexArray :: WebGLContext -> Effect WebGLVertexArrayObject
//foreign import bindVertexArray :: WebGLContext -> WebGLVertexArrayObject -> Effect Unit
//foreign import deleteVertexArray :: WebGLContext -> WebGLVertexArrayObject -> Effect Unit
export function createVertexArray(gl) {
  return () => {
    return gl.createVertexArray();
  };
}
export function bindVertexArray(gl) {
  return function(vao) {
    return () => {
      gl.bindVertexArray(vao);
    };
  };
}
export function deleteVertexArray(gl) {
  return function(vao) {
    return () => {
      gl.deleteVertexArray(vao)
    };
  };
}
export function resizeCanvasToDisplaySize(gl) {
  return function() {
    const canvas = gl.canvas;
    if (canvas === null) {
      console.log("Failed to get canvas for resize");
      return;
    }

    if (canvas.clientWidth !== canvas.width || canvas.clientHeight !== canvas.height) {
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;

      console.log(`Resizing canvas to ${canvas.width}x${canvas.height}`);
      gl.viewport(0,0,canvas.width,canvas.height);
    }
  };
};
export function canvasWidth(gl) {
  return function() {
    return gl.canvas.width;
  };
};
export function canvasHeight(gl) {
  return function() {
    return gl.canvas.height;
  };
};