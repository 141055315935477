import * as $foreign from "./foreign.js";
var FontFamily = /* #__PURE__ */ (function () {
    function FontFamily(value0) {
        this.value0 = value0;
    };
    FontFamily.create = function (value0) {
        return new FontFamily(value0);
    };
    return FontFamily;
})();
var loadFont = function (v) {
    return function (v1) {
        return $foreign.loadFont_(v.value0)(v1.value0);
    };
};
export {
    FontFamily,
    loadFont
};
