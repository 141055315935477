export function showImageData_(imgData) {
  return imgData.toString();
}
export function makeLabelImageData(labelImage) {
  return function(text) {
    return () => {
      const elem = document.createElement("canvas")
      const ctx = elem.getContext("2d");

      const s = 512;
      ctx.canvas.width = s;
      ctx.canvas.height = s;
      ctx.clearRect(0,0,s,s);

      // get image scale
      const ratio = Math.min(
        s / labelImage.naturalWidth,
        s / labelImage.naturalHeight,
      );
      const imageX = (s - (ratio * labelImage.naturalWidth))/2;
      const imageY = (s - (ratio * labelImage.naturalHeight))/2;

      ctx.drawImage(labelImage, imageX, imageY, labelImage.naturalWidth * ratio, labelImage.naturalHeight * ratio);
      

      const fontFace = "PermanentMarker";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#0e25f0";
      
      // image base size 4182x1407
      // text area size 1486 210 2622 1144
      // font likes to go a bit off the edge, so we remove a bit width
      const textCenterX = imageX + (1486 + 2622/2)*ratio;
      const textCenterY = imageY + (210 + 1144/2)*ratio;
      const textWidth = 2400*ratio;
      const textHeight = 1144*ratio;

      // measure font size
      ctx.font = `1px ${fontFace}`;
      let newSize = textWidth / ctx.measureText(text).width;
      if (newSize > textHeight) {
        newSize = textHeight;
      }
      ctx.font = `${Math.floor(newSize)}px ${fontFace}`;

      ctx.fillText(text, textCenterX, textCenterY);
      console.log(elem);
      return ctx.canvas;
    }
  };
}