// Generated by purs version 0.15.10
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Lens from "../Data.Lens.Lens/index.js";
import * as Effect from "../Effect/index.js";
import * as Graphics_Matrix from "../Graphics.Matrix/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Object_Entity from "../Object.Entity/index.js";
var sequence_ = /* #__PURE__ */ Data_Foldable.sequence_(Effect.applicativeEffect)(Data_Foldable.foldableArray);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var Text = /* #__PURE__ */ (function () {
    function Text(value0) {
        this.value0 = value0;
    };
    Text.create = function (value0) {
        return new Text(value0);
    };
    return Text;
})();
var showText = {
    show: function (v) {
        return "Text{}";
    }
};
var renderSingle = function (assets) {
    return function (ctx) {
        return function (perspective) {
            return function (v) {
                var view = Data_Function.flip(Graphics_Matrix.mat4Multiply)(Graphics_Matrix.mat4FromQuat(v.value0.rotation))(Data_Function.flip(Graphics_Matrix.mat4Translate)(v.value0.position)(Graphics_Matrix.mat4Identity));
                return Object_Entity.withDefaultModelBindings(ctx)(assets.textModel)(assets.textAttribs)(perspective)([ view ])(function __do() {
                    Graphics_WebGL.activeTexture0(ctx)();
                    Graphics_WebGL.bindTexture(ctx)(v.value0.texture)();
                    Graphics_WebGL.uniform1i(ctx)(assets.textAttribs.uTexture)(0)();
                    return Graphics_WebGL.drawArraysInstanced(ctx)(0)(assets.textModel.vertexCount)(1)();
                });
            };
        };
    };
};
var render = function (assets) {
    return function (ctx) {
        return function (perspective) {
            return function (ts) {
                return function __do() {
                    Graphics_WebGL.useProgram(ctx)(assets.textProgram.program)();
                    Graphics_WebGL.enableAlphaBlend(ctx)(Graphics_WebGL.blendFuncOne(ctx))(Graphics_WebGL.blendFuncOneMinusSrcAlpha(ctx))();
                    Graphics_WebGL.depthMask(ctx)(false)();
                    sequence_(map(function (t) {
                        return renderSingle(assets)(ctx)(perspective)(t);
                    })(ts))();
                    Graphics_WebGL.disableAlphaBlend(ctx)();
                    return Graphics_WebGL.depthMask(ctx)(true)();
                };
            };
        };
    };
};
var _text = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0;
    })(function (v) {
        return function (r) {
            return new Text(r);
        };
    })(dictStrong);
};
var _rotation = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.rotation;
    })(function (v) {
        return function (r) {
            return new Text({
                position: v.value0.position,
                rotation: r,
                texture: v.value0.texture
            });
        };
    })(dictStrong);
};
var _position = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.position;
    })(function (v) {
        return function (p) {
            return new Text({
                position: p,
                rotation: v.value0.rotation,
                texture: v.value0.texture
            });
        };
    })(dictStrong);
};
export {
    Text,
    _position,
    _rotation,
    _text,
    render,
    showText
};
