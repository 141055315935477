// Generated by purs version 0.15.10
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Lens from "../Data.Lens.Lens/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Graphics_Matrix from "../Graphics.Matrix/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Object_Entity from "../Object.Entity/index.js";
import * as Record from "../Record/index.js";
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var showRecord1 = /* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "a";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "b";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "g";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "r";
    }
})(Data_Show.showNumber))(Data_Show.showNumber))(Data_Show.showNumber))(Data_Show.showNumber));
var show = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ showRecord(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "colorA";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "colorB";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "colorBow";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "position";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "rotation";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "scale";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "topPositionOffset";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "topRotationOffset";
    }
})(Graphics_Matrix.showQuat))(Graphics_Matrix.showVec3))(Graphics_Matrix.showVec3))(Graphics_Matrix.showQuat))(Graphics_Matrix.showVec3))(showRecord1))(showRecord1))(showRecord1)));
var merge = /* #__PURE__ */ Record.merge()();
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var Present = /* #__PURE__ */ (function () {
    function Present(value0) {
        this.value0 = value0;
    };
    Present.create = function (value0) {
        return new Present(value0);
    };
    return Present;
})();
var showPresent = {
    show: function (v) {
        return "Present " + show(v.value0);
    }
};
var withColorModelBindings = function (ctx) {
    return function (attribs) {
        return function (ps) {
            return function (cb) {
                return Graphics_WebGL.withBuffer(ctx)(function (buffer) {
                    var colorData = Graphics_WebGL.float32ArrayConcat(Data_Array.concatMap(function (v) {
                        return [ Graphics_WebGL.colorToArray(v.value0.colorA), Graphics_WebGL.colorToArray(v.value0.colorB), Graphics_WebGL.colorToArray(v.value0.colorBow) ];
                    })(ps));
                    return function __do() {
                        Graphics_WebGL.bindBuffer(ctx)(buffer)(Graphics_WebGL.webGLArrayBufferTarget(ctx))();
                        Graphics_WebGL.floatBufferData(ctx)(Graphics_WebGL.webGLArrayBufferTarget(ctx))(Graphics_WebGL.webGLDynamicDraw(ctx))(colorData)();
                        var common = {
                            size: 4,
                            normalized: false,
                            stride: (3 * 4 | 0) * 4 | 0
                        };
                        Graphics_WebGL.vertexAttributePointer(ctx)(attribs.iColorA)(merge(common)({
                            offset: 0
                        }))();
                        Graphics_WebGL.vertexAttributeDivisor(ctx)(attribs.iColorA)(1)();
                        Graphics_WebGL.vertexAttributePointer(ctx)(attribs.iColorB)(merge(common)({
                            offset: 4 * 4 | 0
                        }))();
                        Graphics_WebGL.vertexAttributeDivisor(ctx)(attribs.iColorB)(1)();
                        Graphics_WebGL.vertexAttributePointer(ctx)(attribs.iColorBow)(merge(common)({
                            offset: 4 * 8 | 0
                        }))();
                        Graphics_WebGL.vertexAttributeDivisor(ctx)(attribs.iColorBow)(1)();
                        return cb();
                    };
                });
            };
        };
    };
};
var render = function (assets) {
    return function (ctx) {
        return function (perspective) {
            return function (ps) {
                var bottomViews = map(function (v) {
                    return Graphics_Matrix.mat4FromRotationTranslationScale(v.value0.rotation)(v.value0.position)(v.value0.scale);
                })(ps);
                var topViews = map(function (v) {
                    return Data_Function.flip(Graphics_Matrix.mat4Multiply)(Graphics_Matrix.mat4FromRotationTranslationScale(v.value0.value0.topRotationOffset)(v.value0.value0.topPositionOffset)(Graphics_Matrix.vec3FromValues(1.0)(1.0)(1.0)))(v.value1);
                })(Data_Array.zip(ps)(bottomViews));
                return function __do() {
                    Graphics_WebGL.useProgram(ctx)(assets.presentProgram.program)();
                    Object_Entity.withDefaultModelBindings(ctx)(assets.presentBottomModel)(assets.presentAttribs)(perspective)(bottomViews)(withColorModelBindings(ctx)(assets.presentAttribs)(ps)(Graphics_WebGL.drawArraysInstanced(ctx)(0)(assets.presentBottomModel.vertexCount)(Data_Array.length(ps))))();
                    return Object_Entity.withDefaultModelBindings(ctx)(assets.presentTopModel)(assets.presentAttribs)(perspective)(topViews)(withColorModelBindings(ctx)(assets.presentAttribs)(ps)(Graphics_WebGL.drawArraysInstanced(ctx)(0)(assets.presentTopModel.vertexCount)(Data_Array.length(ps))))();
                };
            };
        };
    };
};
var _topRotationOffset = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.topRotationOffset;
    })(function (v) {
        return function (pos) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: v.value0.rotation,
                topRotationOffset: pos,
                scale: v.value0.scale,
                colorA: v.value0.colorA,
                colorB: v.value0.colorB,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
var _topPositionOffset = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.topPositionOffset;
    })(function (v) {
        return function (pos) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: pos,
                rotation: v.value0.rotation,
                topRotationOffset: v.value0.topRotationOffset,
                scale: v.value0.scale,
                colorA: v.value0.colorA,
                colorB: v.value0.colorB,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
var _scale = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.scale;
    })(function (v) {
        return function (s) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: v.value0.rotation,
                topRotationOffset: v.value0.topRotationOffset,
                scale: s,
                colorA: v.value0.colorA,
                colorB: v.value0.colorB,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
var _rotation = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.rotation;
    })(function (v) {
        return function (r) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: r,
                topRotationOffset: v.value0.topRotationOffset,
                scale: v.value0.scale,
                colorA: v.value0.colorA,
                colorB: v.value0.colorB,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
var _position = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.position;
    })(function (v) {
        return function (pos) {
            return new Present({
                position: pos,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: v.value0.rotation,
                topRotationOffset: v.value0.topRotationOffset,
                scale: v.value0.scale,
                colorA: v.value0.colorA,
                colorB: v.value0.colorB,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
var _colorBow = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.colorBow;
    })(function (v) {
        return function (v1) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: v.value0.rotation,
                topRotationOffset: v.value0.topRotationOffset,
                scale: v.value0.scale,
                colorA: v.value0.colorA,
                colorB: v.value0.colorB,
                colorBow: v1
            });
        };
    })(dictStrong);
};
var _colorB = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.colorB;
    })(function (v) {
        return function (v1) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: v.value0.rotation,
                topRotationOffset: v.value0.topRotationOffset,
                scale: v.value0.scale,
                colorA: v.value0.colorA,
                colorB: v1,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
var _colorA = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.value0.colorA;
    })(function (v) {
        return function (v1) {
            return new Present({
                position: v.value0.position,
                topPositionOffset: v.value0.topPositionOffset,
                rotation: v.value0.rotation,
                topRotationOffset: v.value0.topRotationOffset,
                scale: v.value0.scale,
                colorA: v1,
                colorB: v.value0.colorB,
                colorBow: v.value0.colorBow
            });
        };
    })(dictStrong);
};
export {
    Present,
    _colorA,
    _colorB,
    _colorBow,
    _position,
    _rotation,
    _scale,
    _topPositionOffset,
    _topRotationOffset,
    render,
    showPresent
};
