// Generated by purs version 0.15.10
import * as $foreign from "./foreign.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Effect from "../Effect/index.js";
var randomRange = function (min) {
    return function (max) {
        return function __do() {
            var n = $foreign.random();
            return n * (max - min) + min;
        };
    };
};
var randomInt = function (low) {
    return function (high) {
        return function __do() {
            var n = $foreign.random();
            var asNumber = ((Data_Int.toNumber(high) - Data_Int.toNumber(low)) + 1) * n + Data_Int.toNumber(low);
            return Data_Int.floor(asNumber);
        };
    };
};
var randomBool = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect)(function (v) {
    return v < 0.5;
})($foreign.random);
export {
    random
} from "./foreign.js";
export {
    randomInt,
    randomRange,
    randomBool
};
