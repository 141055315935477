export function showVec3_(v) {
  return v.toString();
}
export function showQuat_(q) {
  return q.toString();
}
export function showMat4_(m) {
  return m.toString();
}
export var mat4Identity = glMatrix.mat4.create();
export const quatIdentity = glMatrix.quat.create();

export function mat4Perspective(opts) {
  const m = glMatrix.mat4.create();
  glMatrix.mat4.perspective(m, opts.fieldOfView, opts.aspect, opts.zNear, opts.zFar);
  return m;
};
export function mat4Translate(m) {
  return function (v) {
    const m2 = glMatrix.mat4.clone(m);
    glMatrix.mat4.translate(m2, m2, v);
    return m2;
  };
};
export function mat4RotateX(m) {
  return function(rad) {
    const m2 = glMatrix.mat4.clone(m);
    glMatrix.mat4.rotateX(m2, m2, rad);
    return m2;
  };
}
export function mat4RotateY(m) {
  return function(rad) {
    const m2 = glMatrix.mat4.clone(m);
    glMatrix.mat4.rotateY(m2, m2, rad);
    return m2;
  };
}
export function mat4RotateZ(m) {
  return function(rad) {
    const m2 = glMatrix.mat4.clone(m);
    glMatrix.mat4.rotateZ(m2, m2, rad);
    return m2;
  };
}
export function mat4Invert(m) {
  const m2 = glMatrix.mat4.clone(m);
  glMatrix.mat4.invert(m2, m2);
  return m2;
}
export function mat4Transpose(m) {
  const m2 = glMatrix.mat4.clone(m);
  glMatrix.mat4.transpose(m2, m2);
  return m2;
}
export function mat4FromQuat(q) {
  const m = glMatrix.mat4.create();
  glMatrix.mat4.fromQuat(m, q);
  return m;
}
export function mat4Multiply(m1) {
  return function(m2) {
    const m3 = glMatrix.mat4.create();
    glMatrix.mat4.multiply(m3, m1, m2);
    return m3;
  };
}
export function mat4Scale(m) {
  return function(v) {
    const m2 = glMatrix.mat4.create();
    glMatrix.mat4.scale(m2, m1, v);
    return m2;
  }
}
export function mat4FromRotationTranslationScale(q) {
  return function(v) {
    return function(s) {
      const m = glMatrix.mat4.create();
      glMatrix.mat4.fromRotationTranslationScale(m, q, v, s);
      return m;
    }
  }
}
export function vec3FromValues(x) {
  return function(y) {
    return function(z) {
      return glMatrix.vec3.fromValues(x,y,z);
    };
  };
}
export function vec3X(v) {
  return v[0];
}
export function vec3Y(v) {
  return v[1];
}
export function vec3Z(v) {
  return v[2];
}

export function quatRotateX(q) {
  return function(rads) {
    const q2 = glMatrix.quat.clone(q);
    glMatrix.quat.rotateX(q2, q2, rads);
    return q2;
  };
}
export function quatRotateY(q) {
  return function(rads) {
    const q2 = glMatrix.quat.clone(q);
    glMatrix.quat.rotateY(q2, q2, rads);
    return q2;
  };
}
export function quatRotateZ(q) {
  return function(rads) {
    const q2 = glMatrix.quat.clone(q);
    glMatrix.quat.rotateZ(q2, q2, rads);
    return q2;
  };
}
export function quatMultiply(q1) {
  return function(q2) {
    const q3 = glMatrix.quat.create();
    glMatrix.quat.multiply(q3, q1, q2);
    return q3;
  }
}