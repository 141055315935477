import * as Data_Lens_Lens from "../Data.Lens.Lens/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
var wrap = /* #__PURE__ */ Data_Newtype.wrap();
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var _wrap = function () {
    return function (dictStrong) {
        return Data_Lens_Lens.lens(wrap)(function (v) {
            return unwrap;
        })(dictStrong);
    };
};
export {
    _wrap
};
