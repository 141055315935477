// Generated by purs version 0.15.10
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Util_File from "../Util.File/index.js";
import * as Util_ObjParser from "../Util.ObjParser/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Either.bindEither);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Either.applicativeEither);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var sum = /* #__PURE__ */ Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt);
var bindExceptT = /* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect.monadEffect);
var bind1 = /* #__PURE__ */ Control_Bind.bind(bindExceptT);
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect.applicativeEffect);
var show1 = /* #__PURE__ */ Data_Show.show(Parsing.showParseError);
var show2 = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Effect.monadEffect);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(bindExceptT);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect.monadEffect));
var bind2 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var rawFaceVertex = function (o) {
    return function (f) {
        var uv = Data_Either["note$prime"](function (v) {
            return "uv index " + (show(f.uvIdx) + " not found");
        })(Data_Array.index(o.uvs)(f.uvIdx));
        var pos = Data_Either["note$prime"](function (v) {
            return "vertex index " + (show(f.vertexIdx) + " not found");
        })(Data_Array.index(o.verticies)(f.vertexIdx));
        var normal = Data_Either["note$prime"](function (v) {
            return "normal index " + (show(f.normalIdx) + " not found");
        })(Data_Array.index(o.normals)(f.normalIdx));
        return bind(pos)(function (pos$prime) {
            return bind(normal)(function (normal$prime) {
                return bind(uv)(function (uv$prime) {
                    return pure([ pos$prime.x, pos$prime.y, pos$prime.z, normal$prime.x, normal$prime.y, normal$prime.z, uv$prime.x, uv$prime.y ]);
                });
            });
        });
    };
};
var numFaceVerticies = function (fs) {
    var l = Data_Array.length(fs);
    return (l - 2 | 0) * 3 | 0;
};
var faceToRawTriangles = function (o) {
    return function (fs) {
        var raws = sequence(map(rawFaceVertex(o))(fs));
        var r0 = bind(raws)(function (raws$prime) {
            return Data_Either.note("no face idx 0")(Data_Array.index(raws$prime)(0));
        });
        return bind(raws)(function (raws$prime) {
            return bind(r0)(function (r0$prime) {
                return new Data_Either.Right(Data_Array.concat(Data_Array.concatMap(function (v) {
                    return [ r0$prime, v.value0, v.value1 ];
                })(Data_Array.zip(Data_Array.drop(1)(raws$prime))(Data_Array.drop(2)(raws$prime)))));
            });
        });
    };
};
var toFloatArray = function (o) {
    return bind(sequence(map(faceToRawTriangles(o))(o.faces)))(function (rawxs) {
        var raw = Data_Array.concat(rawxs);
        return new Data_Either.Right({
            posOffset: 0,
            normalOffset: 3 * 4 | 0,
            uvOffset: 6 * 4 | 0,
            stride: 8 * 4 | 0,
            vertexCount: sum(map(numFaceVerticies)(o.faces)),
            raw: raw
        });
    });
};
var loadModelToContextFromSource = function (name) {
    return function (source) {
        return function (ctx) {
            return Control_Monad_Except_Trans.runExceptT(bind1(except((function () {
                var v = Util_ObjParser.parseObj(source);
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left("Failed to load model " + (name + (": " + show1(v.value0))));
                };
                if (v instanceof Data_Either.Right) {
                    return pure(v.value0);
                };
                throw new Error("Failed pattern match at Graphics.Model (line 84, column 19 - line 86, column 24): " + [ v.constructor.name ]);
            })()))(function (obj) {
                return bind1(except((function () {
                    var v = toFloatArray(obj);
                    if (v instanceof Data_Either.Left) {
                        return new Data_Either.Left("Failed to convert model " + (name + (" to raw floats: " + show2(v.value0))));
                    };
                    if (v instanceof Data_Either.Right) {
                        return pure(v.value0);
                    };
                    throw new Error("Failed pattern match at Graphics.Model (line 87, column 19 - line 89, column 24): " + [ v.constructor.name ]);
                })()))(function (raw) {
                    return bind1(lift(Graphics_WebGL.createBuffer(ctx)))(function (buf) {
                        return discard(lift(Graphics_WebGL.bindBuffer(ctx)(buf)(Graphics_WebGL.webGLArrayBufferTarget(ctx))))(function () {
                            return discard(lift(Graphics_WebGL.floatBufferData(ctx)(Graphics_WebGL.webGLArrayBufferTarget(ctx))(Graphics_WebGL.webGLStaticDraw(ctx))(Graphics_WebGL.float32ArrayCreate(raw.raw))))(function () {
                                return pure1({
                                    posOffset: raw.posOffset,
                                    normalOffset: raw.normalOffset,
                                    uvOffset: raw.uvOffset,
                                    stride: raw.stride,
                                    vertexCount: raw.vertexCount,
                                    buffer: buf
                                });
                            });
                        });
                    });
                });
            }));
        };
    };
};
var loadModelToContext = function (v) {
    return function (ctx) {
        return bind2(Util_File.loadTextFile(v))(function (source) {
            return liftEffect(loadModelToContextFromSource(v.value0)(source)(ctx));
        });
    };
};
export {
    toFloatArray,
    loadModelToContext
};
