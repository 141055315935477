// Generated by purs version 0.15.10
import * as $foreign from "./foreign.js";
import * as Animation_Animation from "../Animation.Animation/index.js";
var percentInRange = /* #__PURE__ */ Animation_Animation.percentInRange(Animation_Animation.completeRangeNumber);
var semiringVec3 = {
    add: function (a) {
        return function (b) {
            var bz = $foreign.vec3Z(b);
            var by = $foreign.vec3Y(b);
            var bx = $foreign.vec3X(b);
            var az = $foreign.vec3Z(a);
            var ay = $foreign.vec3Y(a);
            var ax = $foreign.vec3X(a);
            return $foreign.vec3FromValues(ax + bx)(ay + by)(az + bz);
        };
    },
    mul: function (a) {
        return function (b) {
            var bz = $foreign.vec3Z(b);
            var by = $foreign.vec3Y(b);
            var bx = $foreign.vec3X(b);
            var az = $foreign.vec3Z(a);
            var ay = $foreign.vec3Y(a);
            var ax = $foreign.vec3X(a);
            return $foreign.vec3FromValues(ax * bx)(ay * by)(az * bz);
        };
    },
    zero: /* #__PURE__ */ $foreign.vec3FromValues(0.0)(0.0)(0.0),
    one: /* #__PURE__ */ $foreign.vec3FromValues(1.0)(1.0)(1.0)
};
var fromNumberVec3 = {
    fromNumber: function (n) {
        return $foreign.vec3FromValues(n)(n)(n);
    }
};
var completeRangeVec3 = {
    percentInRange: function (from) {
        return function (to) {
            return function (t) {
                var toZ = $foreign.vec3Z(to);
                var toY = $foreign.vec3Y(to);
                var toX = $foreign.vec3X(to);
                var fromZ = $foreign.vec3Z(from);
                var fromY = $foreign.vec3Y(from);
                var fromX = $foreign.vec3X(from);
                return $foreign.vec3FromValues(percentInRange(fromX)(toX)(t))(percentInRange(fromY)(toY)(t))(percentInRange(fromZ)(toZ)(t));
            };
        };
    }
};
var showVec3 = {
    show: $foreign.showVec3_
};
var showQuat = {
    show: $foreign.showQuat_
};
var showMat4 = {
    show: $foreign.showMat4_
};
var normalMatrixFromView = function ($7) {
    return $foreign.mat4Invert($foreign.mat4Transpose($7));
};
export {
    mat4FromQuat,
    mat4FromRotationTranslationScale,
    mat4Identity,
    mat4Invert,
    mat4Multiply,
    mat4Perspective,
    mat4RotateX,
    mat4RotateY,
    mat4RotateZ,
    mat4Scale,
    mat4Translate,
    mat4Transpose,
    quatIdentity,
    quatMultiply,
    quatRotateX,
    quatRotateY,
    quatRotateZ,
    showMat4_,
    vec3FromValues,
    vec3X,
    vec3Y,
    vec3Z
} from "./foreign.js";
export {
    normalMatrixFromView,
    showVec3,
    showQuat,
    showMat4,
    completeRangeVec3,
    fromNumberVec3,
    semiringVec3
};
