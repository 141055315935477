// Generated by purs version 0.15.10
var $$Proxy = /* #__PURE__ */ (function () {
    function $$Proxy() {

    };
    $$Proxy.value = new $$Proxy();
    return $$Proxy;
})();
export {
    $$Proxy as Proxy
};
