// Generated by purs version 0.15.10
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Graphics_Matrix from "../Graphics.Matrix/index.js";
var div = /* #__PURE__ */ Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt);
var max = /* #__PURE__ */ Data_Ord.max(Data_Ord.ordInt);
var mod = /* #__PURE__ */ Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt);
var unfoldr = /* #__PURE__ */ Data_Unfoldable.unfoldr(Data_List_Types.unfoldableList);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var spread = function (n) {
    var cols = Data_Int.ceil(Data_Number.pow(Data_Int.toNumber(n))(0.5));
    var row = function (i) {
        return div(i)(cols);
    };
    var rows = div(n - 1 | 0)(cols) + 1 | 0;
    var rowSize = Data_Int.toNumber(rows - 1 | 0) * 3.0;
    var rowMin = (rowSize / 2.0) * -1.0;
    var zSize = Data_Int.toNumber(max(rows)(3)) * 3.0;
    var colSize = Data_Int.toNumber(cols - 1 | 0) * 3.0;
    var colMin = (colSize / 2.0) * -1.0;
    var col = function (i) {
        return mod(i)(cols);
    };
    var vec = function (i) {
        if (i >= n) {
            return Data_Maybe.Nothing.value;
        };
        if (Data_Boolean.otherwise) {
            return new Data_Maybe.Just(Graphics_Matrix.vec3FromValues(colMin + 3.0 * Data_Int.toNumber(col(i)))(rowMin + 3.0 * Data_Int.toNumber(row(i)))(zSize * -1.0 - 6.0));
        };
        throw new Error("Failed pattern match at Util.Grid (line 27, column 5 - line 30, column 67): " + [ i.constructor.name ]);
    };
    return unfoldr(function (i) {
        return bind(vec(i))(function (v) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(v, i + 1 | 0));
        });
    })(0);
};
export {
    spread
};
