// Generated by purs version 0.15.10
import * as $foreign from "./foreign.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var $$null = function (s) {
    return s === "";
};
var localeCompare = /* #__PURE__ */ (function () {
    return $foreign["_localeCompare"](Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value);
})();
export {
    replace,
    replaceAll,
    split,
    toLower,
    toUpper,
    trim,
    joinWith
} from "./foreign.js";
export {
    $$null as null,
    localeCompare
};
