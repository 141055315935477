// Generated by purs version 0.15.10
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Util_File from "../Util.File/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect.monadEffect));
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Effect.monadEffect);
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect.applicativeEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect.monadEffect));
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var loadProgramToContextFromSource = function (vertexSource) {
    return function (fragmentSource) {
        return function (ctx) {
            return Control_Monad_Except_Trans.runExceptT(bind(lift(Graphics_WebGL.loadShader(ctx)(Graphics_WebGL.webGLVertexShader(ctx))(vertexSource)))(function (vertexShader) {
                return bind(except((function () {
                    if (vertexShader instanceof Data_Either.Left) {
                        return new Data_Either.Left("failed to load vertex shader; " + vertexShader.value0);
                    };
                    if (vertexShader instanceof Data_Either.Right) {
                        return new Data_Either.Right(vertexShader.value0);
                    };
                    throw new Error("Failed pattern match at Graphics.Shader (line 22, column 20 - line 24, column 25): " + [ vertexShader.constructor.name ]);
                })()))(function (vs) {
                    return bind(lift(Graphics_WebGL.loadShader(ctx)(Graphics_WebGL.webGLFragmentShader(ctx))(fragmentSource)))(function (fragmentShader) {
                        return bind(except((function () {
                            if (fragmentShader instanceof Data_Either.Left) {
                                return new Data_Either.Left("failed to load fragment shader: " + fragmentShader.value0);
                            };
                            if (fragmentShader instanceof Data_Either.Right) {
                                return new Data_Either.Right(fragmentShader.value0);
                            };
                            throw new Error("Failed pattern match at Graphics.Shader (line 26, column 20 - line 28, column 25): " + [ fragmentShader.constructor.name ]);
                        })()))(function (fs) {
                            return bind(lift(Graphics_WebGL.loadProgram(ctx)(vs)(fs)))(function (program) {
                                return bind(except((function () {
                                    if (program instanceof Data_Either.Left) {
                                        return new Data_Either.Left("failed to compile program: " + program.value0);
                                    };
                                    if (program instanceof Data_Either.Right) {
                                        return new Data_Either.Right(program.value0);
                                    };
                                    throw new Error("Failed pattern match at Graphics.Shader (line 31, column 19 - line 33, column 25): " + [ program.constructor.name ]);
                                })()))(function (p) {
                                    return pure({
                                        vertexShader: vs,
                                        fragmentShader: fs,
                                        program: p
                                    });
                                });
                            });
                        });
                    });
                });
            }));
        };
    };
};
var loadProgramToContext = function (vertex) {
    return function (frag) {
        return function (ctx) {
            return bind1(Util_File.loadTextFile(vertex))(function (vertexSource) {
                return bind1(Util_File.loadTextFile(frag))(function (fragSource) {
                    return liftEffect(loadProgramToContextFromSource(vertexSource)(fragSource)(ctx));
                });
            });
        };
    };
};
export {
    loadProgramToContextFromSource,
    loadProgramToContext
};
