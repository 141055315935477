// Generated by purs version 0.15.10
import * as $foreign from "./foreign.js";
import * as Animation_Animation from "../Animation.Animation/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var percentInRange = /* #__PURE__ */ Animation_Animation.percentInRange(Animation_Animation.completeRangeNumber);
var WrappedColor = function (x) {
    return x;
};
var newtypeWrappedColor_ = {
    Coercible0: function () {
        return undefined;
    }
};
var completeRangeWrappedColor = {
    percentInRange: function (v) {
        return function (v1) {
            return function (t) {
                return {
                    r: percentInRange(v.r)(v1.r)(t),
                    g: percentInRange(v.g)(v1.g)(t),
                    b: percentInRange(v.b)(v1.b)(t),
                    a: percentInRange(v.a)(v1.a)(t)
                };
            };
        };
    }
};
var withVAO = function (ctx) {
    return function (cb) {
        return function __do() {
            var vao = $foreign.createVertexArray(ctx)();
            $foreign.bindVertexArray(ctx)(vao)();
            cb();
            return $foreign.deleteVertexArray(ctx)(vao)();
        };
    };
};
var withBuffer = function (ctx) {
    return function (cb) {
        return function __do() {
            var buf = $foreign.createBuffer(ctx)();
            cb(buf)();
            return $foreign.deleteBuffer(ctx)(buf)();
        };
    };
};
var showFloat32Array = {
    show: $foreign.showFloat32Array_
};
var mat4ToArray = Unsafe_Coerce.unsafeCoerce;
var loadShader = /* #__PURE__ */ (function () {
    return $foreign.loadShader_(Data_Either.Left.create)(Data_Either.Right.create);
})();
var loadProgram = /* #__PURE__ */ (function () {
    return $foreign.loadProgram_(Data_Either.Left.create)(Data_Either.Right.create);
})();
var getWebGLContext = /* #__PURE__ */ (function () {
    return $foreign.getWebGLContext_(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
})();
export {
    activeTexture0,
    bindBuffer,
    bindTexture,
    bindVertexArray,
    blendFuncOne,
    blendFuncOneMinusSrcAlpha,
    blendFuncSrcAlpha,
    canvasHeight,
    canvasWidth,
    clear,
    clearColor,
    colorToArray,
    createBuffer,
    createTexture,
    createVertexArray,
    deleteBuffer,
    deleteProgram,
    deleteShader,
    deleteVertexArray,
    depthMask,
    disableAlphaBlend,
    drawArrays,
    drawArraysInstanced,
    enableAlphaBlend,
    enableDepthBuffer,
    float32ArrayConcat,
    float32ArrayCreate,
    floatBufferData,
    generateMipmap,
    getAttributeLocation,
    getUniformLocation,
    getWebGLContext_,
    loadProgram_,
    loadShader_,
    pixelStoreiUnpackFlipY,
    pixelStoreiUnpackPremultiplyAlpha,
    requestAnimationFrame,
    resizeCanvasToDisplaySize,
    showFloat32Array_,
    texImage2D,
    uniform1i,
    uniformMatrix4fv,
    useProgram,
    vertexAttributeDivisor,
    vertexAttributePointer,
    webGLArrayBufferTarget,
    webGLCopyReadBufferTarget,
    webGLCopyWriteBufferTarget,
    webGLDynamicDraw,
    webGLElementArrayBufferTarget,
    webGLFragmentShader,
    webGLOffsetLocation,
    webGLPixelPackBufferTarget,
    webGLPixelUnpackBufferTarget,
    webGLStaticDraw,
    webGLUniformBufferTarget,
    webGLVertexShader
} from "./foreign.js";
export {
    WrappedColor,
    getWebGLContext,
    loadProgram,
    loadShader,
    mat4ToArray,
    withBuffer,
    withVAO,
    showFloat32Array,
    newtypeWrappedColor_,
    completeRangeWrappedColor
};
