// Generated by purs version 0.15.10
import * as Animation_Animation from "../Animation.Animation/index.js";
import * as Animation_Present from "../Animation.Present/index.js";
import * as Assets from "../Assets/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_EuclideanRing from "../Data.EuclideanRing/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Fold from "../Data.Lens.Fold/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Lens from "../Data.Lens.Lens/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map from "../Data.Map/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Set from "../Data.Set/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Graphics_Matrix from "../Graphics.Matrix/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Object_Present from "../Object.Present/index.js";
import * as Object_Text from "../Object.Text/index.js";
import * as Util_Event from "../Util.Event/index.js";
import * as Util_Grid from "../Util.Grid/index.js";
import * as Util_Shared from "../Util.Shared/index.js";
import * as Util_Shuffle from "../Util.Shuffle/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_Set.foldableSet);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var fromFoldable1 = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var mod = /* #__PURE__ */ Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var fromFoldable2 = /* #__PURE__ */ Data_List.fromFoldable(Data_Foldable.foldableArray);
var elem = /* #__PURE__ */ Data_Array.elem(Data_Eq.eqString);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(Data_Ord.ordString);
var $$delete = /* #__PURE__ */ Data_Array["delete"](Data_Eq.eqString);
var delete1 = /* #__PURE__ */ Data_Map_Internal["delete"](Data_Ord.ordString);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var discard2 = /* #__PURE__ */ discard(Effect_Aff.bindAff);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var fromFoldable3 = /* #__PURE__ */ Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_List_Types.foldableList);
var BeforeReveal = /* #__PURE__ */ (function () {
    function BeforeReveal() {

    };
    BeforeReveal.value = new BeforeReveal();
    return BeforeReveal;
})();
var BeforeShuffle = /* #__PURE__ */ (function () {
    function BeforeShuffle() {

    };
    BeforeShuffle.value = new BeforeShuffle();
    return BeforeShuffle;
})();
var BeforeSelection = /* #__PURE__ */ (function () {
    function BeforeSelection() {

    };
    BeforeSelection.value = new BeforeSelection();
    return BeforeSelection;
})();
var Selection = /* #__PURE__ */ (function () {
    function Selection(value0) {
        this.value0 = value0;
    };
    Selection.create = function (value0) {
        return new Selection(value0);
    };
    return Selection;
})();
var AfterOpen = /* #__PURE__ */ (function () {
    function AfterOpen(value0) {
        this.value0 = value0;
    };
    AfterOpen.create = function (value0) {
        return new AfterOpen(value0);
    };
    return AfterOpen;
})();
var throwOnLeft = function (dictShow) {
    var show1 = Data_Show.show(dictShow);
    return function (e) {
        if (e instanceof Data_Either.Left) {
            return Effect_Exception["throw"](show1(e.value0));
        };
        if (e instanceof Data_Either.Right) {
            return pure(e.value0);
        };
        throw new Error("Failed pattern match at Main (line 92, column 17 - line 94, column 22): " + [ e.constructor.name ]);
    };
};
var throwOnLeft1 = /* #__PURE__ */ throwOnLeft(Data_Show.showString);
var handleGetWorld = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function __do() {
                    var w = Util_Shared.readVar(wv)();
                    return w;
                };
            };
        };
    };
};
var handleGetOrder = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function __do() {
                    var w = Util_Shared.readVar(wv)();
                    return w.order;
                };
            };
        };
    };
};
var handleGetNames = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function __do() {
                    var w = Util_Shared.readVar(wv)();
                    return fromFoldable(Data_Map.keys(w.presents));
                };
            };
        };
    };
};
var defaultColorBow = {
    r: 0.855,
    g: 0.647,
    b: 0.125,
    a: 1.0
};
var defaultColorB = {
    r: 7.0e-2,
    g: 0.82,
    b: 0.25,
    a: 1.0
};
var defaultColorA = {
    r: 0.82,
    g: 7.0e-2,
    b: 7.0e-2,
    a: 1.0
};
var defaultPresent = function (pos) {
    return new Object_Present.Present({
        colorA: defaultColorA,
        colorB: defaultColorB,
        colorBow: defaultColorBow,
        position: pos,
        topPositionOffset: Graphics_Matrix.vec3FromValues(0.0)(0.0)(0.0),
        rotation: Graphics_Matrix.quatIdentity,
        topRotationOffset: Graphics_Matrix.quatIdentity,
        scale: Graphics_Matrix.vec3FromValues(1.0)(1.0)(1.0)
    });
};
var animationFrame = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function (dt) {
                    return function __do() {
                        Graphics_WebGL.resizeCanvasToDisplaySize(ctx)();
                        var width = Graphics_WebGL.canvasWidth(ctx)();
                        var height = Graphics_WebGL.canvasHeight(ctx)();
                        var perspective = Graphics_Matrix.mat4Perspective({
                            fieldOfView: (45.0 * 3.1415926535) / 180.0,
                            aspect: width / height,
                            zNear: 0.1,
                            zFar: 100.0
                        });
                        var w = Util_Shared.readVar(wv)();
                        var a = Util_Shared.readVar(av)();
                        var v = Animation_Animation.step(a)(w)(dt);
                        Util_Shared.setVar(wv)(v.value0.state)();
                        Util_Shared.setVar(av)(v.value0.animation)();
                        v.value0.effect();
                        Object_Present.render(assets)(ctx)(perspective)(fromFoldable1(Data_Map_Internal.values(v.value0.state.presents)))();
                        Object_Text.render(assets)(ctx)(perspective)(fromFoldable1(Data_Map_Internal.values(v.value0.state.texts)))();
                        return Graphics_WebGL.requestAnimationFrame(animationFrame(assets)(ctx)(wv)(av))();
                    };
                };
            };
        };
    };
};
var _texts = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.texts;
    })(function (v) {
        return function (v1) {
            return {
                texts: v1,
                order: v.order,
                presents: v.presents,
                state: v.state
            };
        };
    })(dictStrong);
};
var _state = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.state;
    })(function (v) {
        return function (v1) {
            return {
                state: v1,
                order: v.order,
                presents: v.presents,
                texts: v.texts
            };
        };
    })(dictStrong);
};
var _state1 = /* #__PURE__ */ _state(Data_Profunctor_Strong.strongFn);
var _presents = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.presents;
    })(function (v) {
        return function (v1) {
            return {
                presents: v1,
                order: v.order,
                state: v.state,
                texts: v.texts
            };
        };
    })(dictStrong);
};
var changeSelection = function (wv) {
    return function (av) {
        return function (offset) {
            return function __do() {
                var w = Util_Shared.readVar(wv)();
                if (w.state instanceof Selection) {
                    var len = Data_Array.length(w.order);
                    var idx = mod(mod(w.state.value0 + offset | 0)(len) + len | 0)(len);
                    Effect_Console.log("old index: " + show(w.state.value0))();
                    Effect_Console.log("new index: " + show(idx))();
                    Effect_Console.log("offset: " + show(offset))();
                    var sel = Data_Maybe.fromMaybe("")(Data_Array.index(w.order)(idx));
                    return Util_Shared.setVar(av)(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                        return Data_Lens_Setter.set(_state1)(new Selection(idx))(s);
                    }), new Data_List_Types.Cons(Animation_Present.selectAnimation(1.0 / 0.15)(fromFoldable2(w.order))(sel)(function (dictStrong) {
                        var _presents1 = _presents(dictStrong);
                        return function (dictChoice) {
                            return _presents1;
                        };
                    }), Data_List_Types.Nil.value))))();
                };
                return Effect_Console.log("Not in selection state")();
            };
        };
    };
};
var handleAddName = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function (name) {
                    return function __do() {
                        var w = Util_Shared.readVar(wv)();
                        var newOrder = (function () {
                            var $119 = elem(name)(w.order);
                            if ($119) {
                                return w.order;
                            };
                            return append1(w.order)([ name ]);
                        })();
                        var newPresents = insert(name)(defaultPresent(Graphics_Matrix.vec3FromValues(0.0)(0.0)(-6.0)))(w.presents);
                        Util_Shared.setVar(wv)({
                            order: newOrder,
                            presents: newPresents,
                            texts: Data_Map_Internal.empty,
                            state: BeforeReveal.value
                        })();
                        return Util_Shared.setVar(av)(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Present.setAllColorAnimation(newPresents)(function (dictStrong) {
                            var _presents1 = _presents(dictStrong);
                            return function (dictChoice) {
                                return _presents1;
                            };
                        })(defaultColorA)(defaultColorB)(defaultColorBow), new Data_List_Types.Cons(Animation_Present.rearrangeAnimation(1.0 / 1.5)(fromFoldable2(newOrder))(function (dictStrong) {
                            var _presents1 = _presents(dictStrong);
                            return function (dictChoice) {
                                return _presents1;
                            };
                        }), Data_List_Types.Nil.value))))();
                    };
                };
            };
        };
    };
};
var handleRemoveName = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function (name) {
                    return function __do() {
                        var w = Util_Shared.readVar(wv)();
                        var newOrder = $$delete(name)(w.order);
                        var newPresents = delete1(name)(w.presents);
                        Util_Shared.setVar(wv)({
                            order: newOrder,
                            presents: newPresents,
                            texts: Data_Map_Internal.empty,
                            state: BeforeReveal.value
                        })();
                        return Util_Shared.setVar(av)(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Present.setAllColorAnimation(newPresents)(function (dictStrong) {
                            var _presents1 = _presents(dictStrong);
                            return function (dictChoice) {
                                return _presents1;
                            };
                        })(defaultColorA)(defaultColorB)(defaultColorBow), new Data_List_Types.Cons(Animation_Present.rearrangeAnimation(1.0 / 1.5)(fromFoldable2(newOrder))(function (dictStrong) {
                            var _presents1 = _presents(dictStrong);
                            return function (dictChoice) {
                                return _presents1;
                            };
                        }), Data_List_Types.Nil.value))))();
                    };
                };
            };
        };
    };
};
var _order = function (dictStrong) {
    return Data_Lens_Lens.lens(function (v) {
        return v.order;
    })(function (v) {
        return function (v1) {
            return {
                order: v1,
                presents: v.presents,
                state: v.state,
                texts: v.texts
            };
        };
    })(dictStrong);
};
var _order1 = /* #__PURE__ */ _order(Data_Lens_Internal_Forget.strongForget);
var proceed = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                return function (v4) {
                    if (v instanceof BeforeReveal) {
                        return function __do() {
                            var w = Util_Shared.readVar(v3)();
                            var ra = Animation_Present.revealAllAnimation(v1)(v2)(Data_Map.keys(w.presents))(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            })(function (dictStrong) {
                                var _texts1 = _texts(dictStrong);
                                return function (dictChoice) {
                                    return _texts1;
                                };
                            })();
                            var anims = Animation_Animation.sequence(new Data_List_Types.Cons(ra, new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                                return Data_Lens_Setter.set(_state1)(BeforeShuffle.value)(s);
                            }), Data_List_Types.Nil.value)));
                            return Util_Shared.setVar(v4)(anims)();
                        };
                    };
                    if (v instanceof BeforeShuffle) {
                        return function __do() {
                            var w = Util_Shared.readVar(v3)();
                            var newOrder = Util_Shuffle.shuffle(w.order)();
                            Util_Shared.setVar(v3)({
                                presents: w.presents,
                                texts: w.texts,
                                order: newOrder,
                                state: w.state
                            })();
                            var a2 = Animation_Present.shuffleAnimation(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            })(w.order)(newOrder)();
                            var a3 = Animation_Present.randomizeAllColorAnimation(w.presents)(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            })();
                            return Util_Shared.setVar(v4)(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Present.unrevealAllAnimation(Data_Map.keys(w.presents))(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            })(function (dictStrong) {
                                var _texts1 = _texts(dictStrong);
                                return function (dictChoice) {
                                    return _texts1;
                                };
                            }), new Data_List_Types.Cons(a2, new Data_List_Types.Cons(a3, new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                                return Data_Lens_Setter.set(_state1)(BeforeSelection.value)(s);
                            }), Data_List_Types.Nil.value))))))();
                        };
                    };
                    if (v instanceof BeforeSelection) {
                        return function __do() {
                            var w = Util_Shared.readVar(v3)();
                            var sel = Data_Maybe.fromMaybe("")(Data_Array.index(w.order)(0));
                            return Util_Shared.setVar(v4)(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Present.selectAnimation(1.0 / 0.3)(fromFoldable2(w.order))(sel)(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            }), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                                return Data_Lens_Setter.set(_state1)(new Selection(0))(s);
                            }), Data_List_Types.Nil.value))))();
                        };
                    };
                    if (v instanceof Selection) {
                        return function __do() {
                            var w = Util_Shared.readVar(v3)();
                            var name = Data_Maybe.fromMaybe("")(Data_Array.index(w.order)(v.value0));
                            var a1 = Animation_Present.openAnimation(v1)(v2)(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            })(function (dictStrong) {
                                var _texts1 = _texts(dictStrong);
                                return function (dictChoice) {
                                    return _texts1;
                                };
                            })(name)();
                            return Util_Shared.setVar(v4)(Animation_Animation.sequence(new Data_List_Types.Cons(a1, new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                                return Data_Lens_Setter.set(_state1)(new AfterOpen(v.value0))(s);
                            }), Data_List_Types.Nil.value))))();
                        };
                    };
                    if (v instanceof AfterOpen) {
                        return function __do() {
                            var w = Util_Shared.readVar(v3)();
                            var name = Data_Maybe.fromMaybe("")(Data_Array.index(w.order)(v.value0));
                            var sel = Data_Maybe.fromMaybe("")(Data_Array.index(w.order)((function () {
                                var $126 = v.value0 === 0;
                                if ($126) {
                                    return 1;
                                };
                                return 0;
                            })()));
                            return Util_Shared.setVar(v4)(Animation_Animation.sequence(new Data_List_Types.Cons(Animation_Present.removeAnimation(function (dictStrong) {
                                var _presents1 = _presents(dictStrong);
                                return function (dictChoice) {
                                    return _presents1;
                                };
                            })(function (dictStrong) {
                                var _texts1 = _texts(dictStrong);
                                return function (dictChoice) {
                                    return _texts1;
                                };
                            })(function (dictStrong) {
                                var _order2 = _order(dictStrong);
                                return function (dictChoice) {
                                    return _order2;
                                };
                            })(name), new Data_List_Types.Cons(Animation_Animation.withState(function (s) {
                                var order = Data_Maybe.fromMaybe(w.order)(Data_Lens_Fold.preview(_order1)(s));
                                return Animation_Present.selectAnimation(1.0 / 1.5)(fromFoldable2(order))(sel)(function (dictStrong) {
                                    var _presents1 = _presents(dictStrong);
                                    return function (dictChoice) {
                                        return _presents1;
                                    };
                                });
                            }), new Data_List_Types.Cons(Animation_Animation.instant(function (s) {
                                return Data_Lens_Setter.set(_state1)(new Selection(0))(s);
                            }), Data_List_Types.Nil.value)))))();
                        };
                    };
                    throw new Error("Failed pattern match at Main (line 119, column 1 - line 119, column 100): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name, v4.constructor.name ]);
                };
            };
        };
    };
};
var handleKeyEvent = function (assets) {
    return function (ctx) {
        return function (wv) {
            return function (av) {
                return function (key) {
                    return function __do() {
                        Effect_Console.log("Handle Key Event!")();
                        var a = Util_Shared.readVar(av)();
                        var w = Util_Shared.readVar(wv)();
                        if (key === " ") {
                            var $129 = !Animation_Animation.finished(a);
                            if ($129) {
                                return Effect_Console.log("Not finished, will not proceed")();
                            };
                            return proceed(w.state)(assets)(ctx)(wv)(av)();
                        };
                        if (key === "k") {
                            return changeSelection(wv)(av)(1)();
                        };
                        if (key === "j") {
                            return changeSelection(wv)(av)(-1 | 0)();
                        };
                        return Data_Unit.unit;
                    };
                };
            };
        };
    };
};
var mainAff = /* #__PURE__ */ bind1(/* #__PURE__ */ liftEffect(/* #__PURE__ */ Graphics_WebGL.getWebGLContext("glcanvas")))(function (ctxM) {
    return bind1((function () {
        if (ctxM instanceof Data_Maybe.Just) {
            return pure1(ctxM.value0);
        };
        if (ctxM instanceof Data_Maybe.Nothing) {
            return liftEffect(Effect_Exception["throw"]("Could not get gl context"));
        };
        throw new Error("Failed pattern match at Main (line 263, column 10 - line 265, column 61): " + [ ctxM.constructor.name ]);
    })())(function (ctx) {
        return discard2(liftEffect(Effect_Console.log("Got Canvas")))(function () {
            return discard2(liftEffect(Graphics_WebGL.enableDepthBuffer(ctx)))(function () {
                return discard2(liftEffect(Graphics_WebGL.clearColor(ctx)({
                    r: 0.0,
                    g: 0.0,
                    b: 0.0,
                    a: 0.0
                })))(function () {
                    return discard2(liftEffect(Graphics_WebGL.clear(ctx)))(function () {
                        return discard2(liftEffect(Effect_Console.log("Clear context")))(function () {
                            return bind1(Assets.loadAssets(ctx))(function (assets$prime) {
                                return bind1(liftEffect(throwOnLeft1(assets$prime)))(function (assets) {
                                    return discard2(liftEffect(Effect_Console.log(assets)))(function () {
                                        var names = [ "Icenith", "Alon", "Avery", "Seamily", "Kateve", "Hike", "Minda" ];
                                        return bind1(liftEffect(Util_Shuffle.shuffle(names)))(function (shuffledNames) {
                                            var positions = Util_Grid.spread(Data_Array.length(names));
                                            var ps = map(function (v) {
                                                return new Data_Tuple.Tuple(v.value0, defaultPresent(v.value1));
                                            })(Data_List.zip(fromFoldable2(names))(positions));
                                            var w = {
                                                presents: fromFoldable3(ps),
                                                texts: Data_Map_Internal.empty,
                                                order: names,
                                                state: BeforeReveal.value
                                            };
                                            return bind1(liftEffect(Util_Shared.createVar(Animation_Animation.NilAnimation.value)))(function (av) {
                                                return bind1(liftEffect(Util_Shared.createVar(w)))(function (wv) {
                                                    return discard2(liftEffect(Util_Event.registerKeyEventHandler(handleKeyEvent(assets)(ctx)(wv)(av))))(function () {
                                                        return discard2(liftEffect(Util_Event.registerAddNameHandler(handleAddName(assets)(ctx)(wv)(av))))(function () {
                                                            return discard2(liftEffect(Util_Event.registerRemoveNameHandler(handleRemoveName(assets)(ctx)(wv)(av))))(function () {
                                                                return discard2(liftEffect(Util_Event.registerGetNamesHandler(handleGetNames(assets)(ctx)(wv)(av))))(function () {
                                                                    return discard2(liftEffect(Util_Event.registerGetOrderHandler(handleGetOrder(assets)(ctx)(wv)(av))))(function () {
                                                                        return discard2(liftEffect(Util_Event.registerGetWorldHandler(handleGetWorld(assets)(ctx)(wv)(av))))(function () {
                                                                            return liftEffect(Graphics_WebGL.requestAnimationFrame(animationFrame(assets)(ctx)(wv)(av)));
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var main = function __do() {
    Effect_Aff.launchAff(mainAff)();
    return Data_Unit.unit;
};
export {
    BeforeReveal,
    BeforeShuffle,
    BeforeSelection,
    Selection,
    AfterOpen,
    _order,
    animationFrame,
    main,
    mainAff
};
