// Generated by purs version 0.15.10
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Font_Font from "../Font.Font/index.js";
import * as Graphics_Model from "../Graphics.Model/index.js";
import * as Graphics_Shader from "../Graphics.Shader/index.js";
import * as Graphics_Text from "../Graphics.Text/index.js";
import * as Graphics_WebGL from "../Graphics.WebGL/index.js";
import * as Image_Image from "../Image.Image/index.js";
import * as Record from "../Record/index.js";
import * as Sound_Sound from "../Sound.Sound/index.js";
import * as Util_File from "../Util.File/index.js";
import * as Util_Shared from "../Util.Shared/index.js";
var lookup = /* #__PURE__ */ Data_Map_Internal.lookup(Data_Ord.ordString);
var insert = /* #__PURE__ */ Data_Map_Internal.insert(Data_Ord.ordString);
var merge = /* #__PURE__ */ Record.merge()();
var bind1 = /* #__PURE__ */ Control_Bind.bind(/* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff));
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_Except_Trans.monadTransExceptT)(Effect_Aff.monadAff);
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Effect_Aff.applicativeAff);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Effect_Aff.monadAff));
var makeLabelTextTexture = function (ctx) {
    return function (assets) {
        return function (name) {
            return function __do() {
                var imgData = Graphics_Text.makeLabelImageData(assets.labelImage)(name)();
                var tex = Graphics_WebGL.createTexture(ctx)();
                Graphics_WebGL.bindTexture(ctx)(tex)();
                Graphics_WebGL.pixelStoreiUnpackFlipY(ctx)(true)();
                Graphics_WebGL.pixelStoreiUnpackPremultiplyAlpha(ctx)(true)();
                Graphics_WebGL.texImage2D(ctx)(imgData)();
                Graphics_WebGL.generateMipmap(ctx)();
                return tex;
            };
        };
    };
};
var getLabelTextTexture = function (ctx) {
    return function (assets) {
        return function (name) {
            return function __do() {
                var textMap = Util_Shared.readVar(assets.textVar)();
                var existingTexM = lookup(name)(textMap);
                if (existingTexM instanceof Data_Maybe.Nothing) {
                    var newTex = makeLabelTextTexture(ctx)(assets)(name)();
                    Util_Shared.setVar(assets.textVar)(insert(name)(newTex)(textMap))();
                    return newTex;
                };
                if (existingTexM instanceof Data_Maybe.Just) {
                    return existingTexM.value0;
                };
                throw new Error("Failed pattern match at Assets (line 94, column 3 - line 100, column 23): " + [ existingTexM.constructor.name ]);
            };
        };
    };
};
var calcCommonAttributeLocations = function (ctx) {
    return function (prog) {
        return function __do() {
            var vPosition = Graphics_WebGL.getAttributeLocation(ctx)(prog)("aVertexPosition")();
            var vNormal = Graphics_WebGL.getAttributeLocation(ctx)(prog)("aVertexNormal")();
            var vUV = Graphics_WebGL.getAttributeLocation(ctx)(prog)("aVertexUV")();
            var iModelViewMatrix = Graphics_WebGL.getAttributeLocation(ctx)(prog)("iModelViewMatrix")();
            var iNormalMatrix = Graphics_WebGL.getAttributeLocation(ctx)(prog)("iNormalMatrix")();
            var uProjectionMatrix = Graphics_WebGL.getUniformLocation(ctx)(prog)("uProjectionMatrix")();
            return {
                vPosition: vPosition,
                vNormal: vNormal,
                vUV: vUV,
                iModelViewMatrix: iModelViewMatrix,
                iNormalMatrix: iNormalMatrix,
                uProjectionMatrix: uProjectionMatrix
            };
        };
    };
};
var calcPresentAttribs = function (ctx) {
    return function (prog) {
        return function __do() {
            var common = calcCommonAttributeLocations(ctx)(prog)();
            var iColorA = Graphics_WebGL.getAttributeLocation(ctx)(prog)("iColorA")();
            var iColorB = Graphics_WebGL.getAttributeLocation(ctx)(prog)("iColorB")();
            var iColorBow = Graphics_WebGL.getAttributeLocation(ctx)(prog)("iColorBow")();
            return merge(common)({
                iColorA: iColorA,
                iColorB: iColorB,
                iColorBow: iColorBow
            });
        };
    };
};
var calcTextAttribs = function (ctx) {
    return function (prog) {
        return function __do() {
            var common = calcCommonAttributeLocations(ctx)(prog)();
            var uTexture = Graphics_WebGL.getUniformLocation(ctx)(prog)("uTexture")();
            return merge(common)({
                uTexture: uTexture
            });
        };
    };
};
var loadAssets = function (ctx) {
    return Control_Monad_Except_Trans.runExceptT(bind1(lift(Graphics_Model.loadModelToContext(new Util_File.SourceFile("models/present_bottom.obj"))(ctx)))(function (presentBottomModel$prime) {
        return bind1(except(presentBottomModel$prime))(function (presentBottomModel) {
            return bind1(lift(Graphics_Model.loadModelToContext(new Util_File.SourceFile("models/present_top.obj"))(ctx)))(function (presentTopModel$prime) {
                return bind1(except(presentTopModel$prime))(function (presentTopModel) {
                    return bind1(lift(Graphics_Shader.loadProgramToContext(new Util_File.SourceFile("shaders/present_vertex.glsl"))(new Util_File.SourceFile("shaders/present_frag.glsl"))(ctx)))(function (presentProgram$prime) {
                        return bind1(except(presentProgram$prime))(function (presentProgram) {
                            return bind1(lift(liftEffect(calcPresentAttribs(ctx)(presentProgram.program))))(function (presentAttribs) {
                                return bind1(lift(Graphics_Model.loadModelToContext(new Util_File.SourceFile("models/quad.obj"))(ctx)))(function (textModel$prime) {
                                    return bind1(except(textModel$prime))(function (textModel) {
                                        return bind1(lift(Graphics_Shader.loadProgramToContext(new Util_File.SourceFile("shaders/text_vertex.glsl"))(new Util_File.SourceFile("shaders/text_frag.glsl"))(ctx)))(function (textProgram$prime) {
                                            return bind1(except(textProgram$prime))(function (textProgram) {
                                                return bind1(lift(liftEffect(calcTextAttribs(ctx)(textProgram.program))))(function (textAttribs) {
                                                    return bind1(lift(liftEffect(Util_Shared.createVar(Data_Map_Internal.empty))))(function (textVar) {
                                                        return bind1(lift(liftEffect(Sound_Sound.loadSound(new Util_File.SourceFile("sounds/shake.wav")))))(function (shakeSound) {
                                                            return bind1(lift(liftEffect(Sound_Sound.loadSound(new Util_File.SourceFile("sounds/open.wav")))))(function (openSound) {
                                                                return bind1(lift(liftEffect(Sound_Sound.loadSound(new Util_File.SourceFile("sounds/stretch.wav")))))(function (stretchSound) {
                                                                    return bind1(lift(liftEffect(Image_Image.loadImage(new Util_File.SourceFile("img/label.png")))))(function (labelImage) {
                                                                        return bind1(lift(liftEffect(Font_Font.loadFont(new Font_Font.FontFamily("PermanentMarker"))(new Util_File.SourceFile("font/PermanentMarker-Regular.ttf")))))(function () {
                                                                            return pure1({
                                                                                presentBottomModel: presentBottomModel,
                                                                                presentTopModel: presentTopModel,
                                                                                presentProgram: presentProgram,
                                                                                presentAttribs: presentAttribs,
                                                                                textModel: textModel,
                                                                                textProgram: textProgram,
                                                                                textAttribs: textAttribs,
                                                                                textVar: textVar,
                                                                                shakeSound: shakeSound,
                                                                                openSound: openSound,
                                                                                stretchSound: stretchSound,
                                                                                labelImage: labelImage
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }));
};
export {
    calcCommonAttributeLocations,
    calcPresentAttribs,
    calcTextAttribs,
    getLabelTextTexture,
    makeLabelTextTexture,
    loadAssets
};
